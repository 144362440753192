<table class="list__table">
	<thead *ngIf="headTemplate" class="list__head">
		<ng-container *ngTemplateOutlet="headTemplate"></ng-container>
	</thead>
	<tbody class="list__body" [sortablejs]="items" [sortablejsOptions]="options">
		<ng-container *ngFor="let item of items">
			<ng-container *ngIf="bodyTemplate">
				<ng-container *ngTemplateOutlet="bodyTemplate; context: {$implicit: item}"></ng-container>
			</ng-container>
		</ng-container>
	</tbody>
</table>
