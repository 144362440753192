import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {LocaleOption} from 'src/app/models';

@Injectable({
	providedIn: 'root',
})
export class LocaleService {
	public language = navigator.language;
	public localeOptions: LocaleOption[] = [
		{
			code: 'en-US',
			name: 'United States of America',
			url: 'https://t30.tepari.com/en-US/',
		},
		{
			code: 'en-AU',
			name: 'Australia',
			url: 'https://t30.tepari.com/en-AU/',
		},
		{
			code: 'en-NZ',
			name: 'New Zealand',
			url: 'https://t30.tepari.com/en-NZ/',
		},
	];

	constructor(@Inject(LOCALE_ID) private currentLocale: string) {}

	// Get/Set
	// --------------------------------------------------

	public get locale() {
		return this.get('locale');
	}

	public set locale(locale: string | null) {
		this.set('locale', locale);
	}

	// Methods
	// --------------------------------------------------

	public detectUserLocale(): void {
		if (this.locale) return;

		switch (this.currentLocale) {
			case 'en-US':
				this.locale = 'en-US';
				break;

			case 'en-AU':
				this.locale = 'en-AU';
				break;

			default:
				this.locale = 'en-NZ';
				break;
		}
	}

	public redirectToUserLocale() {
		if (!this.locale || this.locale === this.currentLocale) return;

		const redirect = this.getCurrentLocaleOption();

		if (redirect) {
			window.location.href = redirect.url;
		}
	}

	public switchLocal(option: LocaleOption): void {
		this.locale = option.code;
		window.location.href = option.url;
	}

	public getLocaleOptionByCode(code: string): LocaleOption | undefined {
		return this.localeOptions.find((option) => option.code === code);
	}

	public getCurrentLocaleOption(): LocaleOption | undefined {
		if (this.locale) {
			return this.getLocaleOptionByCode(this.locale);
		} else {
			return;
		}
	}

	// Methods (Util)
	// --------------------------------------------------

	private get(key: string): string | null {
		return localStorage.getItem(key);
	}

	private set(key: string, val: string | null): void {
		if (val) {
			localStorage.setItem(key, val);
		} else {
			localStorage.removeItem(key);
		}
	}
}
