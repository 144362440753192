import {Pipe, PipeTransform} from '@angular/core';
import {groupBy, isArray} from 'lodash-es';

type Iteratee<T> = string | ((value: T) => unknown);

interface Group<T> {
	key: string | number;
	value: T[];
}

@Pipe({
	name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
	transform<T>(collection: T[] | null, iteratee: Iteratee<T>): Group<T>[] | null {
		if (!collection) return collection;

		if (!isArray(collection)) {
			throw new Error(`GroupByPipe: Collection is not iterable.`);
		}

		const groups = groupBy<T>(collection, iteratee);

		return Object.keys(groups).map((key) => ({
			key: key,
			value: groups[key],
		}));
	}
}
