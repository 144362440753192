import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {trigger, transition, useAnimation} from '@angular/animations';
import {uniqueId} from 'lodash-es';
import {collapse, expand} from 'src/app/core';

const panelAnimation = trigger('panelAnimation', [
	transition(':leave', useAnimation(collapse, {params: {timings: '200ms ease-in-out'}})),
	transition(':enter', useAnimation(expand, {params: {timings: '200ms ease-in-out'}})),
]);

@Component({
	selector: 'app-panel',
	host: {
		'class': 'panel',
		'[class.-disabled]': 'disabled',
		'[class.-guide]': 'showGuide',
	},
	templateUrl: './panel.component.html',
	styleUrls: ['./panel.component.scss'],
	animations: [panelAnimation],
	encapsulation: ViewEncapsulation.None,
})
export class PanelComponent {
	public labelId = uniqueId('panel-label-');
	public panelId = uniqueId('panel-content-');
	public rotate = -90;

	@Input() active = false;
	@Input() disabled = false;
	@Input() showGuide = true;

	@Output() panelOpen: EventEmitter<undefined> = new EventEmitter<undefined>();
	@Output() panelClose: EventEmitter<undefined> = new EventEmitter<undefined>();

	constructor(private cdRef: ChangeDetectorRef) {}

	public toggle(): void {
		if (this.active) {
			this.close();
		} else {
			this.open();
		}
	}

	public open(): void {
		if (this.disabled) return;

		this.panelOpen.emit();
		this.active = true;
		this.rotate = 0;
		this.cdRef.detectChanges();
	}

	public close(): void {
		if (this.disabled) return;

		this.panelClose.emit();
		this.active = false;
		this.rotate = -90;
		this.cdRef.detectChanges();
	}
}
