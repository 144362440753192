<ng-container *ngIf="tableService?.tableData?.layouts">
	<!-- Picker -->
	<button type="button" class="table-layout-picker" [dropdown]="layoutMenu">
		<span class="table-layout-picker__label">
			{{ tableService.selectedLayout?.name || 'Select a layout' }}
		</span>
		<app-icon class="table-layout-picker__icon" name="arrow-small"></app-icon>
	</button>

	<!-- Menu -->
	<app-dropdown #layoutMenu class="table-layout-menu" [tip]="false" placement="bottom-start">
		<app-dropdown-content>
			<div class="table-layout-option" [class.-active]="!tableService.selectedLayout">
				<a role="button" class="table-layout-option__content" (click)="selectLayout(null)">
					<p class="table-layout-option__title">None</p>
					<p class="table-layout-option__desc">Use the default table layout.</p>
				</a>
			</div>
			<ng-container *ngFor="let layout of tableService.tableData.layouts">
				<div class="table-layout-option" [class.-active]="isLayoutActive(layout)">
					<a role="button" class="table-layout-option__content" (click)="selectLayout(layout)">
						<p class="table-layout-option__title">
							<span class="mr-1">{{ layout.name }}</span>
							<span *ngIf="layout.default" class="table-layout-option__label">Default</span>
						</p>
						<p class="table-layout-option__desc">{{ layout.description }}</p>
					</a>
					<div class="table-layout-option__controls">
						<button
							i18n-title
							title="Update table layout"
							class="table-layout-option__control -success"
							(click)="updateLayout(layout)"
						>
							<app-icon name="edit"></app-icon>
						</button>
						<button
							i18n-title
							title="Delete table layout"
							class="table-layout-option__control -danger"
							(click)="deleteLayout(layout)"
						>
							<app-icon name="trash"></app-icon>
						</button>
					</div>
				</div>
			</ng-container>
		</app-dropdown-content>
		<app-dropdown-buttons>
			<button
				type="button"
				btn
				theme="primary"
				fill="solid"
				shape="square"
				size="medium"
				(click)="createLayout()"
			>
				<ng-container i18n>Create a layout</ng-container>
			</button>
		</app-dropdown-buttons>
	</app-dropdown>

	<!-- Drawer-->
	<app-drawer #drawerSaveLayout (drawerDidClose)="onDrawerClose()">
		<app-drawer-body>
			<h4 class="mb-3">Edit layout</h4>
			<app-table-layout-form
				*ngIf="drawerSaveLayout.active"
				[parent]="drawerSaveLayout"
				[closeParent]="true"
				(onSubmit)="applyLayout()"
			></app-table-layout-form>
		</app-drawer-body>
	</app-drawer>
</ng-container>
