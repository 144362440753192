import {Pipe, PipeTransform} from '@angular/core';
import {get, isArray, isObject, isString} from 'lodash-es';

@Pipe({
	name: 'field',
})
export class FieldPipe implements PipeTransform {
	transform(object: any, path: string | string[]): any {
		if (!object) return object;

		if (!isObject(object)) {
			throw new Error(`FieldPipe: value is not an object.`);
		}

		if (!isString(path) && !isArray(path)) {
			return null;
		}

		let val: string;

		if (isArray(path)) {
			const tempVal: string[] = [];

			path.forEach((p) => {
				tempVal.push(this.getValue(object, p));
			});

			val = tempVal.join(' ');
		} else {
			val = this.getValue(object, path);
		}

		return val;
	}

	getValue(object: any, path: string): string {
		let val = get(object, path);

		// some data is null as a string, not sure why...
		if ((!val && val !== 0) || val === 'null') {
			val = '';
		}

		return val;
	}
}
