import {trigger, transition, useAnimation} from '@angular/animations';
import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {expand, collapse} from 'src/app/core';
import {SessionService} from 'src/app/core';

const dropdownAnimation = trigger('dropdownAnimation', [
	transition(':leave', useAnimation(collapse, {params: {timings: '200ms ease-in-out'}})),
	transition(':enter', useAnimation(expand, {params: {timings: '200ms ease-in-out'}})),
]);

interface NavItem {
	path: string;
	label: string;
	icon?: string;
	dropdownOpen?: boolean;
	dropdownItems?: NavItem[];
}

@Component({
	selector: 'app-nav',
	templateUrl: './nav.component.html',
	styleUrls: ['./nav.component.scss'],
	animations: [dropdownAnimation],
})
export class NavComponent implements OnInit, OnDestroy {
	public isMenuOpen = false;
	public navItems!: NavItem[];
	public unsubscribe$: Subject<boolean> = new Subject();

	constructor(public session: SessionService, private renderer: Renderer2, private router: Router) {}

	public ngOnInit(): void {
		this.navItems = [
			{
				path: '/animals',
				label: $localize`Animals`,
				icon: 'squares',
				dropdownOpen: false,
				dropdownItems: [
					{path: '/animals/mobs', label: $localize`Mobs`},
					{path: '/animals/groups', label: $localize`Groups`},
					{path: '/animals/list', label: $localize`All Animals`},
				],
			},
			{
				path: '/measurements',
				label: $localize`Measurements`,
				icon: 'scales',
				dropdownItems: [
					{path: '/measurements/events', label: $localize`Measured`},
					{
						path: '/measurements/not-measured',
						label: $localize`Not Measured`,
					},
					{
						path: '/measurements/settings',
						label: $localize`Settings`,
					},
				],
			},
			{
				path: '/treatments',
				label: $localize`Treatments`,
				icon: 'vile',
				dropdownItems: [
					{path: '/treatments/events', label: $localize`Treatments`},
					{path: '/treatments/settings', label: $localize`Settings`},
				],
			},
			{
				path: '/alerts',
				label: $localize`Alerts`,
				icon: 'bell',
			},
			{
				path: '/sales-and-purchases',
				label: $localize`Sales & Purchases`,
				icon: 'arrows',
				dropdownItems: [
					{
						path: '/sales-and-purchases/sales',
						label: $localize`Sales`,
					},
					{
						path: '/sales-and-purchases/purchases',
						label: $localize`Purchases`,
					},
					{
						path: '/sales-and-purchases/contacts',
						label: $localize`Contacts`,
					},
				],
			},
			{
				path: '/help-and-support',
				label: $localize`Help & Support`,
				icon: 'lightning',
			},
			{
				path: '/import',
				label: $localize`Import`,
				icon: 'doc-add',
			},
		];

		this.router.events
			.pipe(
				takeUntil(this.unsubscribe$),
				filter((event) => event instanceof NavigationEnd),
			)
			.subscribe(() => {
				this.closeAllDropdowns();
				this.closeMenu();
			});
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	// Menu
	// --------------------------------------------------

	private openMenu(): void {
		this.isMenuOpen = true;
		this.renderer.addClass(document.body, 'menu-open');
	}

	private closeMenu(): void {
		this.isMenuOpen = false;
		this.renderer.removeClass(document.body, 'menu-open');
	}

	public toggleMenu(): void {
		if (this.isMenuOpen) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	}

	// Dropdown
	// --------------------------------------------------

	public closeAllDropdowns(): void {
		this.navItems.forEach((item) => (item.dropdownOpen = false));
	}

	public dropdownsEnabled(): boolean {
		return window.matchMedia('(min-width: 992px)').matches;
	}
}
