import {
	Component,
	AfterContentInit,
	QueryList,
	ContentChildren,
	ViewEncapsulation,
	Input,
	OnDestroy,
} from '@angular/core';
import {Subject} from 'rxjs';
import {PanelComponent} from 'src/app/shared/components/panel/panel.component';

@Component({
	selector: 'app-accordion',
	host: {class: 'accordion'},
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AccordionComponent implements AfterContentInit, OnDestroy {
	@ContentChildren(PanelComponent, {descendants: false})
	panels!: QueryList<PanelComponent>;

	@Input() openFirst = false;
	@Input() keepOpen = false;

	public unsubscribe$: Subject<boolean> = new Subject();

	constructor() {}

	public ngAfterContentInit(): void {
		this.openFirstPanel();
		this.onPanelOpen();
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	private onPanelOpen() {
		if (this.keepOpen) return;

		this.panels.toArray().forEach((panel: PanelComponent) => {
			panel.panelOpen.subscribe(() => {
				this.closeOtherPanels(panel);
			});
		});
	}

	private openFirstPanel() {
		if (!this.openFirst) return;

		const activePanel = this.panels.find((t) => t.active);

		if (activePanel) return;

		const panelToOpen = this.panels.find((t) => !t.disabled);

		if (!panelToOpen) return;

		panelToOpen.open();
	}

	private closeOtherPanels(panel: PanelComponent): void {
		this.panels.toArray().forEach((p) => {
			if (p.panelId !== panel.panelId && p.active) {
				p.close();
			}
		});
	}
}
