import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
import {InputSwitchComponent} from './input-switch.component';

const components = [InputSwitchComponent];

@NgModule({
	imports: [CommonModule, IconModule],
	declarations: [...components],
	exports: [...components],
})
export class InputSwitchModule {}
