import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {EventEmitter, Injectable, Output} from '@angular/core';
import {Router} from '@angular/router';
import {interval, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {isAfter, isValid} from 'date-fns';
import {environment} from 'src/environments/environment';
import {Response, UserLogin} from 'src/app/models';
import {CacheService, raygunSetUser} from 'src/app/core';

@Injectable({
	providedIn: 'root',
})
export class SessionService {
	@Output() userLoggedIn = new EventEmitter<null>();
	@Output() userLoggedOut = new EventEmitter<null>();

	private readonly baseUrl = environment.api;
	private timer: Observable<number>;

	constructor(private httpClient: HttpClient, private cache: CacheService, private router: Router) {
		// Renew the access token every hour
		this.timer = interval(3600000);

		this.timer.subscribe(() => {
			this.renewToken();
		});
	}

	// Get/Set
	// --------------------------------------------------

	public get token() {
		return this.get('token');
	}

	public set token(token: string | null) {
		this.set('token', token);
	}

	public get tokenExpires() {
		return this.get('token-expires');
	}

	public set tokenExpires(date: string | null) {
		this.set('token-expires', date);
	}

	public get refreshToken() {
		return this.get('refresh-token');
	}

	public set refreshToken(refreshToken: string | null) {
		this.set('refresh-token', refreshToken);
	}

	public get username() {
		return this.get('username');
	}

	public set username(username: string | null) {
		this.set('username', username);
	}

	// Methods
	// --------------------------------------------------

	public login(username: string, password: string): Observable<Response<UserLogin>> {
		const body = {
			username,
			password,
			grantType: 'password',
		};

		return this.httpClient.post<Response<UserLogin>>(`${this.baseUrl}/users/login`, body).pipe(
			map((res) => {
				if (res.status === 'success') {
					this.refreshToken = res.data.refreshToken;
					this.token = res.data.token;
					this.tokenExpires = res.data.expires;
					this.username = res.data.username;
					this.userLoggedIn.emit();

					raygunSetUser(this.username);
				}

				return res;
			}),
		);
	}

	public logout(): void {
		this.refreshToken = null;
		this.token = null;
		this.tokenExpires = null;
		this.username = null;
		this.cache.clear();
		this.userLoggedOut.emit();
		this.router.navigate(['/login']);
	}

	public renewToken(): void {
		if (!this.username || !this.refreshToken) return;

		const body = {
			username: this.username,
			refreshToken: this.refreshToken,
		};

		this.httpClient
			.post<Response<UserLogin>>(`${this.baseUrl}/users/refresh-token`, body)
			.pipe(
				catchError((err: HttpErrorResponse) => {
					this.logout();
					return throwError(err);
				}),
			)
			.subscribe((res) => {
				if (res.status === 'success') {
					this.refreshToken = res.data.refreshToken;
					this.token = res.data.token;
					this.tokenExpires = res.data.expires;
					this.username = res.data.username;
				} else {
					this.logout();
				}
			});
	}

	public isLoggedIn(): boolean {
		const hasUsername = this.username ? true : false;
		const hasValidToken = this.isTokenValid();

		return hasUsername && hasValidToken;
	}

	public isTokenValid(): boolean {
		const expires = this.tokenExpires;

		if (!expires) return false;

		const expiryDate = new Date(expires);

		if (!isValid(expiryDate)) return false;

		return isAfter(expiryDate, new Date());
	}

	// Methods (Util)
	// --------------------------------------------------

	private get(key: string): string | null {
		return localStorage.getItem(key);
	}

	private set(key: string, val: string | null): void {
		if (val) {
			localStorage.setItem(key, val);
		} else {
			localStorage.removeItem(key);
		}
	}
}
