import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {cloneDeep, clone} from 'lodash-es';
import {FilterMetadata} from 'primeng/api';
import {DropdownComponent} from 'src/app/shared/components/dropdown/dropdown.component';
import {TableService} from '../../services/table.service';
import {TableColumn} from '../../models';
import {TableFilterFormComponent} from '../table-filter-form/table-filter-form.component';

@Component({
	selector: 'app-table-filter',
	host: {
		class: 'table-filter',
	},
	templateUrl: './table-filter.component.html',
	styleUrls: ['./table-filter.component.scss'],
	// encapsulation: ViewEncapsulation.None,
})
export class TableFilterComponent implements OnInit {
	@ViewChild('filterMenu') filterMenu!: DropdownComponent;
	@ViewChild('filterForm') filterForm!: TableFilterFormComponent;

	@Input() column!: TableColumn;

	public filters: FilterMetadata[] = [];

	get table() {
		return this.tableService.table;
	}

	get field() {
		return this.column.filterField || this.column.field;
	}

	get type() {
		return this.column.filterType || 'text';
	}

	get options$() {
		return this.column.filterOptions$;
	}

	get optionsLabel() {
		return this.column.filterOptionsLabel;
	}

	get optionsValue() {
		return this.column.filterOptionsValue || this.column.filterOptionsLabel;
	}

	constructor(public tableService: TableService) {}

	public ngOnInit() {
		this.table.onFilter.subscribe(() => this.updateMetadata());
	}

	public updateMetadata() {
		if (!this.table || !this.field) return;

		if (this.field in this.table.filters) {
			const values = this.table.filters[this.field];
			this.filters = Array.isArray(values) ? cloneDeep(values) : [clone(values)];
		} else {
			this.filterForm.clearFilters();
		}
	}

	public applyFilters() {
		if (!this.table || !this.field) return;

		this.table.filters[this.field] = cloneDeep(this.filters);
		this.table._filter();
		this.filterMenu.close();
	}

	public clearFilters() {
		this.filterForm.clearFilters();
		this.applyFilters();
	}
}
