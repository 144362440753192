import {Component, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {SearchBoxService} from './search-box.service';

@Component({
	selector: 'app-search-box',
	host: {
		class: 'search',
	},
	templateUrl: './search-box.component.html',
	styleUrls: ['./search-box.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SearchBoxComponent {
	constructor(public searchBoxService: SearchBoxService) {}

	public submit(form: NgForm): void {
		if (form.invalid) return;

		const terms = form.value.terms;

		if (!terms) return;

		this.searchBoxService.search(terms);
	}
}
