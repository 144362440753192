import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressSpinnerModule} from 'src/app/shared/components/progress-spinner/progress-spinner.module';

import {EmbedComponent} from './embed.component';
import {EmbedItemDirective} from './directives/embed-item.directive';
import {EmbedOverlayDirective} from './directives/embed-overlay.directive';

const components = [EmbedComponent, EmbedItemDirective, EmbedOverlayDirective];

@NgModule({
	imports: [CommonModule, ProgressSpinnerModule],
	declarations: [...components],
	exports: [...components],
})
export class EmbedModule {}
