<app-content>
	<div id="wrapper">
		<figure id="logo">
			<app-icon name="logo"></app-icon>
			<p>T30</p>
		</figure>

		<app-card id="request-password-reset">
			<ng-container *ngIf="token; then resetFormTemp; else requestFormTemp"></ng-container>
		</app-card>
	</div>
</app-content>

<ng-template #requestFormTemp>
	<form id="request-form" #form="ngForm" (ngSubmit)="requestPasswordReset(form)" novalidate autocomplete="off">
		<app-alert *ngIf="message">
			<p>{{ message }}</p>
		</app-alert>

		<app-input>
			<label input-label i18n>Username</label>
			<input input-control type="text" name="username" [(ngModel)]="username" required />
			<small input-error match="required"
				>'<span i18n>Username</span>' <span i18n>is a required field</span>.</small
			>
		</app-input>

		<app-btn-group [block]="true">
			<button type="submit" btn theme="primary" shape="rounded" [loading]="loading">
				<span i18n>Request Password Reset</span>
			</button>

			<a [routerLink]="['/login']" btn theme="none" shape="rounded">
				<span i18n>Go to Login</span>
			</a>
		</app-btn-group>
	</form>
</ng-template>

<ng-template #resetFormTemp>
	<form id="reset-form" #form="ngForm" (ngSubmit)="resetPassword(form)" novalidate autocomplete="off">
		<app-alert *ngIf="message">
			<p>{{ message }}</p>
		</app-alert>

		<input type="hidden" name="token" [value]="token" />

		<app-input>
			<label input-label i18n>Password</label>
			<input
				input-control
				type="password"
				name="p1"
				#p1="ngModel"
				[(ngModel)]="password1"
				compare="p2"
				required
			/>
			<small input-error match="required"
				>'<span i18n>Password</span>' <span i18n>is a required field</span>.</small
			>
			<small input-error match="compare"><span i18n>Passwords do not match</span>.</small>
		</app-input>

		<app-input>
			<label input-label i18n>Retype Password</label>
			<input
				input-control
				type="password"
				name="p2"
				#p2="ngModel"
				[(ngModel)]="password2"
				compare="p1"
				required
			/>
			<small input-error match="required"
				>'<span i18n>Retype Password</span>' <span i18n>is a required field</span>.</small
			>
			<small input-error match="compare"><span i18n>Passwords do not match</span>.</small>
		</app-input>

		<app-btn-group [block]="true">
			<button type="submit" btn theme="primary" shape="rounded" [loading]="loading">
				<span i18n>Reset Password</span>
			</button>

			<a [routerLink]="['/login']" btn theme="none" shape="rounded">
				<span i18n>Go to Login</span>
			</a>
		</app-btn-group>
	</form>
</ng-template>
