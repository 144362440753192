import {Component, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-panel-table, [panel-table]',
	host: {
		class: 'panel__table',
	},
	template: `
		<table>
			<thead>
				<ng-content select="tr[panel-table-head]"></ng-content>
			</thead>
			<tbody>
				<ng-content select="tr[panel-table-row]"></ng-content>
			</tbody>
		</table>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class PanelTableComponent {}
