import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {CounterComponent} from './counter.component';

const components = [CounterComponent];

@NgModule({
	imports: [CommonModule, RouterModule],
	declarations: [...components],
	exports: [...components],
})
export class CounterModule {}
