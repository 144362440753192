<form #form="ngForm" (ngSubmit)="submit(form)" novalidate autocomplete="off">
	<ng-container *ngIf="selectedLayout">
		<app-input icon="edit">
			<label input-label i18n>Name</label>
			<input input-control type="text" name="name" [(ngModel)]="selectedLayout.name" required />
			<small input-error match="required" i18n>'Name' is a required field</small>
		</app-input>

		<app-input icon="edit">
			<label input-label i18n>Description</label>
			<input input-control type="text" name="description" [(ngModel)]="selectedLayout.description" />
		</app-input>

		<!-- <app-input-radio class="my-3" label="Set as default">
			<input type="checkbox" name="default" [(ngModel)]="selectedLayout.default" />
		</app-input-radio> -->

		<app-sortable-list class="my-3" [items]="selectedLayout.columns" [headTemplate]="head" [bodyTemplate]="body">
			<ng-template #head>
				<tr>
					<th width="1%"></th>
					<th width="33%">Item</th>
					<th width="33%">Filter By</th>
					<th width="33%">Sort By</th>
				</tr>
			</ng-template>
			<ng-template #body let-layoutColumn>
				<ng-container *ngIf="getColumnByField(layoutColumn.field) as column">
					<tr
						class="table-layout-form__column-option"
						[class.-disabled]="layoutColumn.enabled === false"
						[class.sort-disabled]="layoutColumn.enableSort === false"
					>
						<td>
							<app-sortable-list-handle></app-sortable-list-handle>
						</td>

						<td>
							<app-input-radio [label]="column.header">
								<input
									type="checkbox"
									[(ngModel)]="layoutColumn.enabled"
									[ngModelOptions]="{standalone: true}"
								/>
							</app-input-radio>
						</td>

						<td>
							<button
								*ngIf="filterForm"
								type="button"
								class="table-layout-form__filter"
								[class.-active]="filterForm.isActive"
								[dropdown]="filterMenu"
								[disabled]="!layoutColumn.enabled"
							>
								<app-icon name="filter"></app-icon>
								{{ filterForm.isActive ? 'Defined' : 'Add rule' }}
							</button>

							<app-dropdown #filterMenu placement="bottom-start" [tip]="false">
								<app-dropdown-content>
									<app-table-filter-form
										#filterForm
										[(filters)]="layoutColumn.filters"
										[field]="column.filterField || column.field"
										[type]="column.filterType || 'text'"
										[options$]="column.filterOptions$"
										[optionsLabel]="column.filterOptionsLabel"
										[optionsValue]="column.filterOptionsValue || column.filterOptionsLabel"
									></app-table-filter-form>
								</app-dropdown-content>

								<app-dropdown-buttons>
									<button
										type="button"
										btn
										theme="primary"
										fill="solid"
										shape="square"
										size="medium"
										(click)="filterMenu.close()"
									>
										<ng-container i18n>Apply</ng-container>
									</button>
									<button
										type="button"
										btn
										theme="primary"
										fill="solid"
										shape="square"
										size="medium"
										(click)="clearFilters(filterMenu, filterForm)"
									>
										<ng-container i18n>Clear</ng-container>
									</button>
								</app-dropdown-buttons>
							</app-dropdown>
						</td>

						<td>
							<button
								type="button"
								class="table-layout-form__sort"
								[ngClass]="{
									'-asc': selectedLayout.sortOrder === 1,
									'-desc': selectedLayout.sortOrder === -1,
									'-active': selectedLayout.sortField === layoutColumn.field
								}"
								(click)="updateSort(layoutColumn)"
								[disabled]="!layoutColumn.enabled"
							>
								<app-icon name="sort"></app-icon>
								{{
									!isSortActive(layoutColumn)
										? 'Apply'
										: selectedLayout.sortOrder === 1
										? 'Asc'
										: 'Desc'
								}}
							</button>
						</td>
					</tr>
				</ng-container>
			</ng-template>
		</app-sortable-list>

		<app-btn-group [block]="true">
			<button
				type="submit"
				btn
				theme="success"
				fill="outline"
				shape="rounded"
				[expand]="true"
				[loading]="loading"
				[disabled]="loading"
			>
				<ng-container i18n>Save</ng-container>
			</button>

			<button
				*ngIf="parent"
				type="button"
				btn
				theme="none"
				shape="rounded"
				[expand]="true"
				[disabled]="loading"
				(click)="parent.close()"
			>
				<ng-container i18n>Close</ng-container>
			</button>
		</app-btn-group>
	</ng-container>
</form>
