<ng-container *ngFor="let notification of notificationsService.notifications; index as i">
	<div class="notifications__alert" [@notificationAnimation]>
		<app-alert
			[theme]="notification.type"
			[icon]="notification.type"
			[dismissable]="true"
			[timer]="notification.timer"
			(alertDismissed)="notificationsService.destroy(i)"
		>
			<p *ngIf="notification.title">
				<strong>{{ notification.title }}</strong>
			</p>
			<p>{{ notification.message }}</p>
		</app-alert>
	</div>
</ng-container>
