<label class="input-select__label" [for]="id">
	{{ label }}
</label>

<select
	class="input-select__control"
	#input
	[id]="id"
	[name]="id"
	[attr.placeholder]="placeholder || label"
	[attr.aria-label]="label"
	[(ngModel)]="value"
	(ngModelChange)="valueChange.emit(value)"
>
	<ng-content></ng-content>
</select>

<app-icon class="input-select__icon" name="arrow-small"></app-icon>
