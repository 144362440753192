<ng-container *ngFor="let filter of filters; let i = index">
	<ng-container *ngIf="matchModes.length > 1">
		<p-dropdown
			[(ngModel)]="filter.matchMode"
			[ngModelOptions]="{standalone: true}"
			[options]="matchModes"
			optionLabel="name"
			optionValue="code"
		></p-dropdown>
	</ng-container>

	<ng-container [ngSwitch]="type">
		<ng-container *ngSwitchCase="'select'">
			<ng-container *ngIf="options$ | async as options; else loading">
				<p-dropdown
					*ngIf="optionsLabel && optionsValue"
					[(ngModel)]="filter.value"
					[ngModelOptions]="{standalone: true}"
					[options]="options"
					[optionLabel]="optionsLabel"
					[optionValue]="optionsValue"
					placeholder="Any"
				></p-dropdown>
			</ng-container>
		</ng-container>

		<ng-container *ngSwitchCase="'date'">
			<p-calendar [(ngModel)]="filter.value"></p-calendar>
		</ng-container>

		<ng-container *ngSwitchCase="'number'">
			<input type="number" class="p-inputtext" [(ngModel)]="filter.value" />
		</ng-container>

		<ng-container *ngSwitchDefault>
			<input type="text" class="p-inputtext" [(ngModel)]="filter.value" />
		</ng-container>
	</ng-container>

	<ng-container *ngIf="type !== 'select'">
		<button
			type="button"
			class="table-filter-form__btn -success"
			[hidden]="filters.length >= 2"
			(click)="addFilter()"
		>
			Add rule
		</button>

		<button
			type="button"
			class="table-filter-form__btn -danger"
			[hidden]="filters.length <= 1"
			(click)="removeFilter(i)"
		>
			Remove rule
		</button>
	</ng-container>
</ng-container>

<ng-template #loading>
	<app-progress-spinner></app-progress-spinner>
</ng-template>
