import {Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {DropdownComponent} from '../dropdown.component';

@Directive({
	selector: '[dropdown]',
})
export class DropdownToggleDirective implements OnInit {
	@Input() dropdown!: DropdownComponent;

	private el!: HTMLElement;

	constructor(private elRef: ElementRef) {}

	public ngOnInit() {
		this.el = this.elRef.nativeElement;
	}

	@HostListener('document:click', ['$event'])
	public onDocumentClick(e: PointerEvent) {
		const targetEl = e.target;

		if (!targetEl || !(targetEl instanceof Element)) return;

		// Toggle click
		if (this.el === targetEl || this.el.contains(targetEl)) {
			if (!this.el.hasAttribute('disabled')) {
				this.dropdown.toggle(this.el);
			}
			return;
		}

		// Body click
		if (this.dropdown.closeOnClickOutside) {
			this.dropdown.close();
		}
	}

	@HostListener('document:keydown', ['$event'])
	public onDocumentKeydown(e: KeyboardEvent) {
		if (e.code === 'Escape') {
			this.dropdown.close();
		}
	}
}
