<div *ngIf="icon" class="alert__icon">
	<app-icon [name]="icon"></app-icon>
</div>

<div class="alert__content">
	<ng-content></ng-content>
</div>

<button *ngIf="dismissable" class="alert__close" (click)="dismiss()">
	<span class="sr-only" i18n>close alert</span>
	<app-icon name="close"></app-icon>
</button>

<div *ngIf="timer" class="alert__timer">
	<app-progress-bar type="determinate" [progress]="progress"></app-progress-bar>
</div>
