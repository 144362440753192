import {trigger, transition, useAnimation, animateChild, group, query} from '@angular/animations';
import {
	Component,
	ViewEncapsulation,
	ElementRef,
	Input,
	OnInit,
	OnDestroy,
	Renderer2,
	Output,
	EventEmitter,
	HostBinding,
	HostListener,
} from '@angular/core';
import {zoomOut, zoomIn, fadeOut, fadeIn} from 'src/app/core';
import {uniqueId} from 'lodash-es';
import {ModalService} from './modal.service';
import {ModalSize, ModalState} from './types';

// prettier-ignore
const modalAnimation = trigger('modalAnimation', [
	transition(
		':leave',
		group([
			query('.modal__backdrop', animateChild()),
			query('.modal__dialog', animateChild())
		])
	),
	transition(
		':enter',
		group([
			query('.modal__backdrop', animateChild()),
			query('.modal__dialog', animateChild())
		])
	)
]);

// prettier-ignore
const modalBackdropAnimation = trigger('modalBackdropAnimation', [
	transition(':leave', useAnimation(fadeOut, {params: {timings: '300ms 100ms ease-out'}})),
	transition(':enter', useAnimation(fadeIn, {params: {timings: '300ms ease-in'}}))
]);

// prettier-ignore
const modalDialogAnimation = trigger('modalDialogAnimation', [
	transition(':leave', useAnimation(zoomOut, {params: {timings: '300ms ease-out', scale: 0.9}})),
	transition(':enter', useAnimation(zoomIn, {params: {timings: '300ms 100ms ease-in', scale: 0.9}}))
]);

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	animations: [modalAnimation, modalBackdropAnimation, modalDialogAnimation],
	encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit, OnDestroy {
	@Input() id!: string;
	@Input() disabled = false;
	@Input() size: ModalSize = 'medium';

	@Output() modalWillOpen: EventEmitter<any> = new EventEmitter<any>();
	@Output() modalDidOpen: EventEmitter<any> = new EventEmitter<any>();
	@Output() modalWillClose: EventEmitter<any> = new EventEmitter<any>();
	@Output() modalDidClose: EventEmitter<any> = new EventEmitter<any>();

	public el!: HTMLElement;
	public active = false;
	public state: ModalState = 'closed';
	public get classes(): string {
		const classes: string[] = [];

		classes.push(`-size-${this.size}`);

		return classes.join(' ');
	}

	constructor(public elRef: ElementRef, public renderer: Renderer2, public modalService: ModalService) {}

	public ngOnInit(): void {
		this.el = this.elRef.nativeElement;

		// Set ID
		if (!this.id) {
			this.id = uniqueId('modal-');
			this.renderer.setAttribute(this.el, 'id', this.id);
		}

		// Set attributes
		this.renderer.setAttribute(this.el, 'role', 'dialog');
		this.renderer.setAttribute(this.el, 'tabindex', '-1');
		this.renderer.setAttribute(this.el, 'aria-hidden', 'true');

		// Add to modalService
		this.modalService.add(this);

		// Move el to the bottom of page
		document.body.appendChild(this.el);
	}

	public ngOnDestroy(): void {
		this.modalService.remove(this.id);
		this.el.remove();
	}

	@HostListener('document:keydown', ['$event'])
	public onEscClick(e: KeyboardEvent) {
		if (e.code === 'Escape') {
			this.close();
		}
	}

	public updateState(e: any) {
		if (e.fromState === 'void') {
			if (e.phaseName === 'start') {
				this.state = 'opening';
				this.modalWillOpen.emit();
			} else {
				this.state = 'open';
				this.modalDidOpen.emit();
			}
		} else {
			if (e.phaseName === 'start') {
				this.state = 'closing';
				this.modalWillClose.emit();
			} else {
				this.state = 'closed';
				this.modalDidClose.emit();
			}
		}
	}

	public async open(): Promise<void> {
		if (this.disabled || this.state === 'opening' || this.state === 'closing') return;

		this.active = true;
		this.renderer.setAttribute(this.el, 'aria-hidden', 'false');
		this.renderer.setAttribute(this.el, 'tabindex', '0');
		this.renderer.addClass(document.body, 'modal-open');
	}

	public async close(): Promise<void> {
		if (this.disabled || this.state === 'opening' || this.state === 'closing') return;

		this.active = false;
		this.renderer.setAttribute(this.el, 'aria-hidden', 'true');
		this.renderer.setAttribute(this.el, 'tabindex', '-1');
		this.renderer.removeClass(document.body, 'modal-open');
	}

	public enable(): ModalComponent {
		this.disabled = false;
		return this;
	}

	public disable(): ModalComponent {
		this.disabled = true;
		return this;
	}
}
