import {Component, Input, TemplateRef, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-sortable-list',
	host: {
		class: 'list',
	},
	templateUrl: './sortable-list.component.html',
	styleUrls: ['./sortable-list.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SortableListComponent {
	@Input() items!: any[];
	@Input() headTemplate!: TemplateRef<any>;
	@Input() bodyTemplate!: TemplateRef<any>;

	public options: any = {
		filter: '.sort-disabled',
		onMove: (e: any) => {
			const relatedEl: HTMLElement | undefined = e.related;

			if (!relatedEl) return;

			if (relatedEl.classList.contains('sort-disabled')) {
				return false;
			} else {
				return true;
			}
		},
	};

	constructor() {}
}
