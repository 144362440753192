import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProgressBarComponent} from './progress-bar.component';

const components = [ProgressBarComponent];

@NgModule({
	imports: [CommonModule],
	declarations: [...components],
	exports: [...components],
})
export class ProgressBarModule {}
