import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {retry, map} from 'rxjs/operators';
import {Response, User} from 'src/app/models';
import {environment} from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private readonly baseUrl = environment.api;

	constructor(private httpClient: HttpClient) {}

	public getUser(userName: string): Observable<User> {
		return this.httpClient.get<Response<User>>(`${this.baseUrl}/users/${userName}`).pipe(map((res) => res.data));
	}

	public updateUser(userName: string, user: User): Observable<User> {
		return this.httpClient
			.put<Response<User>>(`${this.baseUrl}/users/${userName}`, user)
			.pipe(map((res) => res.data));
	}

	public updatePassword(userName: string, password: string, oldPassword: string): Observable<string> {
		const body = {
			password,
			oldPassword,
		};

		return this.httpClient
			.post<Response<string>>(`${this.baseUrl}/users/${userName}/update-password`, body)
			.pipe(map((res) => res.status));
	}

	public resetPassword(password: string, resetToken: string): Observable<string> {
		const body = {
			password,
			resetToken,
		};

		return this.httpClient
			.post<Response<string>>(`${this.baseUrl}/users/reset-password`, body)
			.pipe(map((res) => res.status));
	}

	public requestPasswordReset(userName: string): Observable<string> {
		return this.httpClient
			.post<Response<string>>(`${this.baseUrl}/users/request-password-reset`, userName)
			.pipe(map((res) => res.status));
	}
}
