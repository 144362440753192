<form #form="ngForm" (ngSubmit)="submit(form)" novalidate autocomplete="off" class="search__form">
	<input
		type="text"
		name="terms"
		i18n-placeholder
		i18n-aria-label
		placeholder="Search"
		aria-label="Search Text"
		class="search__input"
		[(ngModel)]="searchBoxService.searchTerms"
	/>

	<button type="submit" i18n-aria-label aria-label="Submit Search" class="search__btn">
		<app-icon name="search"></app-icon>
	</button>
</form>
