import {Component, ContentChildren, Input, QueryList, ViewEncapsulation} from '@angular/core';
import {ButtonComponent} from '../button/button.component';

@Component({
	selector: 'app-btn-group, [btn-group]',
	host: {
		'class': 'btn-group',
		'[class.-block]': 'block',
	},
	templateUrl: './button-group.component.html',
	styleUrls: ['./button-group.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ButtonGroupComponent {
	@ContentChildren(ButtonComponent) buttons!: QueryList<ButtonComponent>;

	@Input() block = false;

	constructor() {}
}
