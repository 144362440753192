import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {IconModule} from 'src/app/shared/components/icon/icon.module';

import {PanelComponent} from './panel.component';
import {PanelTableComponent} from './components/panel-table.component';
import {PanelActionsDirective} from './directives/panel-actions.directive';
import {PanelContentDirective} from './directives/panel-content.directive';
import {PanelTableHeadDirective} from './directives/panel-table-head.directive';
import {PanelTableRowDirective} from './directives/panel-table-row.directive';
import {PanelTitleDirective} from './directives/panel-title.directive';

const components = [
	PanelComponent,
	PanelTableComponent,
	PanelActionsDirective,
	PanelContentDirective,
	PanelTableHeadDirective,
	PanelTableRowDirective,
	PanelTitleDirective,
];

@NgModule({
	imports: [CommonModule, PortalModule, IconModule],
	declarations: [...components],
	exports: [...components],
})
export class PanelModule {}
