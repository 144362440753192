import {style, animate, animation} from '@angular/animations';

// prettier-ignore
export const slideInX = animation([
	style({transform: 'translateX({{ offset }})'}), 
	animate(
		'{{ timings }}', 
		style({transform: 'translateX(0)'})
	)
]);

// prettier-ignore
export const slideOutX = animation([
	style({transform: 'translateX(0)'}), 
	animate(
		'{{ timings }}', 
		style({transform: 'translateX({{ offset }})'})
	)
]);
