<app-progress-bar *ngIf="loading" id="loading"></app-progress-bar>

<app-icon-definitions></app-icon-definitions>

<app-notifications></app-notifications>

<ng-container *ngIf="init">
	<aside id="aside" *ngIf="isNavActive()">
		<app-nav></app-nav>
	</aside>

	<main id="main">
		<router-outlet></router-outlet>
	</main>
</ng-container>
