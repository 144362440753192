<div
	*ngIf="active"
	class="drawer"
	[ngClass]="classes"
	[@drawerAnimation]
	(@drawerAnimation.start)="updateState($event)"
	(@drawerAnimation.done)="updateState($event)"
>
	<div class="drawer__backdrop" [@drawerBackdropAnimation] (click)="close()"></div>

	<div class="drawer__dialog" [@drawerDialogAnimation]>
		<div class="drawer__close" [@drawerCloseAnimation]>
			<button
				type="button"
				aria-label="Close drawer"
				class=""
				btn-icon
				theme="primary"
				shape="round"
				(click)="close()"
			>
				<app-icon name="close"></app-icon>
			</button>
		</div>

		<div class="drawer__content">
			<ng-content></ng-content>
		</div>
	</div>
</div>
