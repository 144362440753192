import {Injectable} from '@angular/core';
import {isPlainObject} from 'lodash-es';
import {environment} from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	private readonly baseUrl = environment.api;

	constructor() {}

	/*
	* Used in place of console.x methods. This allows us to disable the messages in prod builds.
	* Import into a component and log using the methods:

	constructor(..., public util: UtilService) {}

	this.util.info( ... )
	this.util.log( ... )
	this.util.warn( ... )
	this.util.error( ... )
	*/

	public info(...values: any[]): void {
		if (!environment.production) console.info(...values);
	}

	public log(...values: any[]): void {
		if (!environment.production) console.log(...values);
	}

	public warn(...values: any[]): void {
		if (!environment.production) console.warn(...values);
	}

	public error(...values: any[]): void {
		if (!environment.production) console.error(...values);
	}

	/*
	* Used to track *ngFor items for improved performance.
	* Import into a component and bind to an *ngFor loop:

	<li *ngFor="let x of x; trackBy: util.trackBy">
		...
	</li>
	*/

	public trackBy(index: number, item: any): number | string {
		const isObj = isPlainObject(item);

		if (isObj && item.id) {
			return item.id;
		}

		if (isObj && item.code) {
			return item.code;
		}

		return index;
	}

	/*
	* Used to match the value of NgModel with the correct options in a select input.
	* Import into a component and bind to a select input:

	<select [(ngModel)]="XXX" [compareWith]="compare.XXX">
		...
	</select>
	*/

	public compareByValue(c1: any, c2: any): boolean {
		console.log(c1, c2);

		return c1 === c2;
	}

	public compareByCode(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.code === c2.code : c1 === c2;
	}

	public compareById(c1: any, c2: any): boolean {
		return c1 && c2 ? c1.id === c2.id : c1 === c2;
	}

	/*
	 * Build a url with optional params.
	 */

	public urlBuilder(baseUrl: string, params: string[] = []) {
		let url: string;
		const httpCheck = new RegExp(/(http|https)/);

		if (!httpCheck.test(baseUrl)) {
			url = `${this.baseUrl}${baseUrl}`;
		} else {
			url = `${baseUrl}`;
		}

		params.forEach((param) => {
			url.includes('?') ? (url += '&') : (url += '?');
			url += param;
		});

		return url;
	}
}
