import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
import {ProgressSpinnerModule} from 'src/app/shared/components/progress-spinner/progress-spinner.module';

import {InputComponent} from './input.component';
import {InputLabelDirective} from './directives/input-label.directive';
import {InputControlDirective} from './directives/input-control.directive';
import {InputErrorDirective} from './directives/input-error.directive';

const components = [InputComponent, InputLabelDirective, InputControlDirective, InputErrorDirective];

@NgModule({
	imports: [CommonModule, IconModule, ProgressSpinnerModule],
	declarations: [...components],
	exports: [...components],
})
export class InputModule {}
