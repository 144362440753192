import {Injectable} from '@angular/core';
import {UtilService} from 'src/app/core';
import {DrawerComponent} from './drawer.component';

@Injectable({providedIn: 'root'})
export class DrawerService {
	private drawers: DrawerComponent[] = [];

	constructor(private util: UtilService) {}

	public add(drawer: DrawerComponent): void {
		this.drawers.push(drawer);
	}

	public remove(id: string): void {
		this.drawers = this.drawers.filter((x) => x.id !== id);
	}

	public async toggle(id: string): Promise<void> {
		const drawer = this.drawers.find((x) => x.id === id);

		if (!drawer) {
			this.util.error(`Drawer "${id}" could not be found.`);
			return;
		}

		if (drawer.state === 'closed') {
			await drawer.open();
		} else {
			await drawer.close();
		}
	}

	public open(id: string): void {
		const drawer = this.drawers.find((x) => x.id === id);

		if (drawer) {
			drawer.open();
		} else {
			this.util.error(`Drawer "${id}" could not be found.`);
		}
	}

	public close(id: string): void {
		const drawer = this.drawers.find((x) => x.id === id);

		if (drawer) {
			drawer.close();
		} else {
			this.util.error(`Drawer "${id}" could not be found.`);
		}
	}
}
