import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {FieldPipe} from './field/field.pipe';
import {GroupByPipe} from './group-by/group-by.pipe';
import {PadStartPipe} from './pad-start/pad-start.pipe';
import {SafeHtmlPipe} from './safe-html/safe-html.pipe';
import {SafeUrlPipe} from './safe-url/safe-url.pipe';
import {SortPipe} from './sort/sort.pipe';
import {SortByPipe} from './sort-by/sort-by.pipe';
import {FilterByPipe} from './filter-by/filter-by.pipe';

const pipes = [FieldPipe, FilterByPipe, GroupByPipe, PadStartPipe, SafeHtmlPipe, SafeUrlPipe, SortPipe, SortByPipe];

@NgModule({
	imports: [CommonModule],
	exports: [...pipes],
	declarations: [...pipes],
})
export class PipesModule {}
