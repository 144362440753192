import {Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';

type InputTheme = 'primary' | 'success' | 'info' | 'warning' | 'danger';

@Component({
	selector: 'app-progress-bar',
	host: {
		class: 'progress',
	},
	template: ` <div class="progress__bar" [style.--progress-percent]="progress + '%'"></div> `,
	styleUrls: ['./progress-bar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProgressBarComponent {
	@Input() theme?: InputTheme;
	@Input() type: 'determinate' | 'indeterminate' = 'indeterminate';
	@Input() progress?: number;

	@HostBinding('class')
	get classes(): string {
		const classArray: string[] = [];

		if (this.theme) {
			classArray.push(`-${this.theme}`);
		}

		classArray.push(`-${this.type}`);

		return classArray.join(' ');
	}

	constructor() {}
}
