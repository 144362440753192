<header class="page-header__content">
	<ng-container *ngTemplateOutlet="links?.length ? linksTemp : titleTemp"></ng-container>
</header>

<nav class="page-header__controls">
	<app-search-box class="page-header__search"></app-search-box>

	<a
		[routerLink]="['/search']"
		[routerLinkActive]="'-active'"
		i18n-aria-label
		aria-label="Search"
		btn-icon
		theme="none"
		shape="round"
		class="page-header__search-btn"
	>
		<app-icon name="search"></app-icon>
	</a>

	<a
		[routerLink]="['/profile']"
		[routerLinkActive]="'-active'"
		i18n-aria-label
		aria-label="My Account"
		btn-icon
		theme="none"
		shape="round"
	>
		<app-icon name="user"></app-icon>
	</a>
</nav>

<ng-template #titleTemp>
	<div class="page-header__back" *ngIf="back">
		<button [routerLink]="back" btn theme="neutral" size="small" fill="outline" shape="round">
			<app-icon name="arrow" [rotate]="180"></app-icon>
			<span i18n>Back</span>
		</button>
	</div>

	<div class="page-header__item">
		<ng-content></ng-content>
	</div>
</ng-template>

<ng-template #linksTemp>
	<ng-container *ngFor="let link of links">
		<a [routerLink]="link.path" [routerLinkActive]="'-active'" class="page-header__link">
			{{ link.label }}
		</a>
	</ng-container>
</ng-template>
