import {style, animate, animation} from '@angular/animations';

// prettier-ignore
export const collapse = animation([
	style({height: '*', overflow: 'hidden'}), 
	animate(
		'{{ timings }}', 
		style({height: 0})
	)
]);
