// Vendor
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

// App
import {AppComponent} from 'src/app/app.component';
import {AppRoutingModule} from 'src/app/app-routing.module';
import {SharedModule} from 'src/app/shared/shared.module';
import {LocaleService, ErrorHandlerService, AuthInterceptor, CacheInterceptor} from 'src/app/core';
import {environment} from 'src/environments/environment';

// i18n
import {registerLocaleData} from '@angular/common';
import localeNZ from '@angular/common/locales/en-NZ';
import localeAU from '@angular/common/locales/en-AU';
import localeUS from '@angular/common/locales/en-US-POSIX';
import {ServiceWorkerModule} from '@angular/service-worker';

registerLocaleData(localeNZ, 'en-NZ');
registerLocaleData(localeAU, 'en-AU');
registerLocaleData(localeUS, 'en-US');

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		SharedModule,
		ServiceWorkerModule.register('ngsw-worker.js', {
			enabled: environment.production,
			registrationStrategy: 'registerImmediately',
		}),
	],
	providers: [
		LocaleService,
		{provide: ErrorHandler, useClass: ErrorHandlerService},
		{provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
		{provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
