import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'src/app/shared/components/button/button.module';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
import {ProgressBarModule} from 'src/app/shared/components/progress-bar/progress-bar.module';

import {AlertComponent} from './alert.component';

const components = [AlertComponent];

@NgModule({
	imports: [CommonModule, ButtonModule, IconModule, ProgressBarModule],
	declarations: [...components],
	exports: [...components],
})
export class AlertModule {}
