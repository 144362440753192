import {Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-alert',
	host: {
		class: 'alert',
		role: 'alert',
	},
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AlertComponent implements OnInit {
	@Input() theme: 'success' | 'info' | 'warning' | 'danger' = 'info';
	@Input() dismissable = false;
	@Input() icon?: string;
	@Input() timer?: number;

	@Output() alertDismissed: EventEmitter<any> = new EventEmitter<any>();

	@HostBinding('class')
	get classes(): string {
		const classes: string[] = [];

		classes.push(`-${this.theme}`);

		if (this.timer) {
			classes.push('-timer');
		}

		return classes.join(' ');
	}

	public progress = 100;
	public interval?: number;

	constructor() {}

	ngOnInit() {
		this.startCountdown();
	}

	startCountdown() {
		if (this.timer === undefined) return;

		let time = 0;

		const target = this.timer || 0;

		this.interval = setInterval(() => {
			time += 10;

			this.progress = (1 - time / target) * 100;

			if (time >= target) {
				clearInterval(this.interval);
				this.alertDismissed.emit();
			}
		}, 10);
	}

	dismiss() {
		clearInterval(this.interval);
		this.alertDismissed.emit();
	}
}
