import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {environment} from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class DevGuard implements CanActivate {
	constructor() {}

	public canActivate() {
		return environment.production !== true;
	}
}
