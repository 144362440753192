import {style, animate, animation} from '@angular/animations';

// prettier-ignore
export const expand = animation([
	style({height: 0, overflow: 'hidden'}), 
	animate(
		'{{ timings }}', 
		style({height: '*'})
	)
]);
