import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-input-radio-button, [input-radio-button]',
	host: {
		class: 'input-radio-button',
	},
	template: `
		<label>
			<ng-content select="input[type='radio']"></ng-content>
			<span>{{ label }}</span>
		</label>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class inputRadioButtonComponent {
	@Input() label!: string;
}
