<button
	class="drawer-nav__button"
	btn
	theme="primary"
	[expand]="true"
	shape="square"
	(click)="prev()"
	[disabled]="disabled || !collection || isFirstItem()"
>
	<app-icon class="drawer-nav__icon" name="arrow" [rotate]="180"></app-icon>
	<span class="drawer-nav__label" i18n>Previous</span>
</button>

<button
	class="drawer-nav__button"
	btn
	theme="primary"
	[expand]="true"
	shape="square"
	(click)="next()"
	[disabled]="disabled || !collection || isLastItem()"
>
	<span class="drawer-nav__label" i18n>Next</span>
	<app-icon class="drawer-nav__icon" name="arrow"></app-icon>
</button>
