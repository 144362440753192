import {Component, ViewChild, ViewEncapsulation} from '@angular/core';
import {TableLayout} from 'src/app/models';
import {DrawerComponent} from 'src/app/shared/components/drawer/drawer.component';
import {DropdownComponent} from 'src/app/shared/components/dropdown/dropdown.component';
import {TableService} from '../../services/table.service';

@Component({
	selector: 'app-table-layout-picker',
	templateUrl: './table-layout-picker.component.html',
	styleUrls: ['./table-layout-picker.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TableLayoutPickerComponent {
	@ViewChild('drawerSaveLayout') drawerSaveLayout!: DrawerComponent;
	@ViewChild('layoutMenu') layoutMenu!: DropdownComponent;

	constructor(public tableService: TableService) {}

	public isLayoutActive(layout: TableLayout) {
		return layout.id === this.tableService.selectedLayout?.id;
	}

	public selectLayout(layout: TableLayout | null) {
		this.layoutMenu.close();
		this.tableService.selectedLayout = layout;

		if (layout) {
			this.tableService.applyLayout(layout);
		} else {
			this.tableService.resetTable();
		}
	}

	public applyLayout() {
		if (this.tableService.selectedLayout) {
			this.tableService.applyLayout(this.tableService.selectedLayout);
		}
	}

	public createLayout() {
		this.layoutMenu.close();
		this.tableService.createLayout();
		this.drawerSaveLayout.open();
	}

	public updateLayout(layout: TableLayout) {
		this.layoutMenu.close();
		this.tableService.selectedLayout = layout;
		this.drawerSaveLayout.open();
	}

	public deleteLayout(layout: TableLayout) {
		if (layout.id === this.tableService.selectedLayout?.id) {
			this.tableService.resetTable();
		}

		this.layoutMenu.close();
		this.tableService.deleteLayout(layout);
	}

	public onDrawerClose() {
		if (this.tableService.selectedLayout?.id) return;

		this.tableService.selectedLayout = null;
		this.tableService.resetTable();
	}
}
