import {Directive, ElementRef} from '@angular/core';

@Directive({
	selector: '[input-label]',
	host: {class: 'input__label'},
})
export class InputLabelDirective {
	public el!: HTMLElement;

	constructor(public elRef: ElementRef) {
		this.el = this.elRef.nativeElement;
	}
}
