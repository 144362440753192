import {Component, ElementRef, Input, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-counter',
	host: {class: 'counter'},
	templateUrl: './counter.component.html',
	styleUrls: ['./counter.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CounterComponent {
	@Input() count!: number;
	@Input() label!: string;

	public el!: HTMLElement;

	constructor(public elRef: ElementRef) {
		this.el = this.elRef.nativeElement;
	}
}
