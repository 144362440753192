<div
	*ngIf="active"
	class="modal"
	[ngClass]="classes"
	[@modalAnimation]
	(@modalAnimation.start)="updateState($event)"
	(@modalAnimation.done)="updateState($event)"
>
	<div class="modal__backdrop" [@modalBackdropAnimation] (click)="close()"></div>

	<article class="modal__dialog" [@modalDialogAnimation]>
		<div class="modal__content">
			<div class="modal__close">
				<button type="button" aria-label="Close Modal" btn-icon theme="white" shape="round" (click)="close()">
					<app-icon name="close"></app-icon>
				</button>
			</div>

			<ng-content></ng-content>
		</div>
	</article>
</div>
