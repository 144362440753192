import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {trigger, transition, query, style, useAnimation} from '@angular/animations';
import {fadeIn, fadeOut} from 'src/app/core/animations/fade.animation';
import {RouterOutlet} from '@angular/router';

const routeAnimation = trigger('routeAnimation', [
	transition('* => *', [
		query(':enter', [style({opacity: 0})], {optional: true}),
		query(':leave', useAnimation(fadeOut, {params: {timings: '200ms ease-in-out'}}), {optional: true}),
		query(':enter', useAnimation(fadeIn, {params: {timings: '200ms ease-in-out'}}), {optional: true}),
	]),
]);

@Component({
	selector: 'app-router',
	host: {
		class: 'router',
	},
	template: `
		<div class="router__wrapper" [@routeAnimation]="outlet.isActivated ? outlet.activatedRoute : ''">
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./router.component.scss'],
	animations: [routeAnimation],
	encapsulation: ViewEncapsulation.None,
})
export class RouterComponent {
	@Input() outlet!: RouterOutlet;

	constructor() {}
}
