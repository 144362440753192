import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
import {InputSelectComponent} from './input-select.component';

const components = [InputSelectComponent];

@NgModule({
	imports: [CommonModule, FormsModule, IconModule],
	declarations: [...components],
	exports: [...components],
})
export class InputSelectModule {}
