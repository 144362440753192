import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

// Directives
import {AutoResizeDirective} from './autoresize/autoresize.directive';
import {LetDirective} from './let/let.directive';
import {RouterLinkDisabledDirective} from './router-link-disabled/router-link-disabled.directive';

// Validators
import {CompareDirective} from './validators/compare.directive';
import {UniqueDirective} from './validators/unique.directive';

const directives = [AutoResizeDirective, CompareDirective, LetDirective, RouterLinkDisabledDirective, UniqueDirective];

@NgModule({
	imports: [CommonModule],
	exports: [...directives],
	declarations: [...directives],
})
export class DirectivesModule {}
