import {Directive} from '@angular/core';
import {Input} from '@angular/core';

@Directive({
	selector: '[dropdown-item]',
	host: {
		'class': 'dropdown__item',
		'[class.-active]': 'active',
	},
})
export class DropdownItemDirective {
	@Input() active = false;
}
