import {Pipe, PipeTransform} from '@angular/core';
import {isArray, isObject, isString, sortBy} from 'lodash-es';

@Pipe({
	name: 'sortBy',
	// pure: false
})
export class SortByPipe implements PipeTransform {
	transform<T>(collection: T[] | null, prop: string, order: 'asc' | 'desc' = 'asc'): T[] | null {
		if (!collection || !collection.length) return collection;

		if (!isArray(collection)) {
			throw new Error(`SortByPipe: Collection is not iterable.`);
		}

		if (!isObject(collection[0])) {
			throw new Error(`SortByPipe: Collection items are not an object.`);
		}

		if (!Object.prototype.hasOwnProperty.call(collection[0], prop)) {
			return collection;
		}

		const sortedCollection = sortBy(collection, (o: any) => {
			const val = o[prop];

			if (isString(val)) {
				return (val as string).toLowerCase();
			} else {
				return val;
			}
		});

		if (order === 'asc') {
			return sortedCollection;
		} else {
			return sortedCollection.reverse();
		}
	}
}
