<header class="tabs__header">
	<nav class="tabs__nav" id="myTab" role="tablist">
		<ng-container *ngFor="let tab of tabs; let i = index">
			<ng-container *ngIf="!tab.hidden">
				<input
					type="button"
					role="tab"
					id="{{ tab.id }}-tab"
					class="tabs__nav-btn"
					[value]="tab.label"
					[class.-active]="tab.active"
					[class.-disabled]="tab.disabled"
					[disabled]="tab.disabled"
					[attr.aria-controls]="tab.id"
					[attr.aria-selected]="tab.active"
					(click)="selectTab(tab)"
				/>
			</ng-container>
		</ng-container>

		<app-input-select class="tabs__nav-select" label="Select a Tab" (valueChange)="selectTabById($event)">
			<ng-container *ngFor="let tab of tabs; let i = index">
				<option [value]="tab.id" [selected]="tab.active" [disabled]="tab.disabled || tab.hidden">
					{{ tab.label }}
				</option>
			</ng-container>
		</app-input-select>
	</nav>

	<ng-content select="app-tab-controls, [tab-controls]"></ng-content>
</header>

<div class="tabs__content">
	<ng-content select="app-tab-pane, [tab-pane]"></ng-content>
</div>
