import {Component, Input, ViewEncapsulation} from '@angular/core';
import {TableColumn} from 'src/app/models';
import {TableService} from '../../services/table.service';

@Component({
	selector: 'app-table-sort',
	templateUrl: './table-sort.component.html',
	styleUrls: ['./table-sort.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TableSortComponent {
	@Input() column!: TableColumn;

	get table() {
		return this.tableService.table;
	}

	constructor(public tableService: TableService) {}
}
