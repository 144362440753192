import {style, animate, animation} from '@angular/animations';

// prettier-ignore
export const fadeIn = animation([
	style({opacity: 0}), 
	animate(
		'{{ timings }}', 
		style({opacity: 1})
	)
]);

// prettier-ignore
export const fadeOut = animation([
	style({opacity: 1}), 
	animate(
		'{{ timings }}', 
		style({opacity: 0})
	)
]);
