import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
// import rg4js from 'raygun4js';
import {UtilService} from './util.service';
import {environment} from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
	constructor(public zone: NgZone, public router: Router, public util: UtilService) {}

	handleError(error: any) {
		console.error(error);

		if (!environment.production) return;

		// rg4js('send', {
		// 	error,
		// });

		let route: string | undefined;

		switch (error.status) {
			case 400:
				route = '/error/400';
				break;

			case 404:
				route = '/error/404';
				break;

			case 500:
				route = '/error/500';
				break;

			// default:
			// 	route = '/error/unknown';
			// 	break;
		}

		if (route) {
			this.zone.run(() => this.router.navigateByUrl(route as string));
		}
	}
}
