import {Component, HostBinding, Input, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-icon',
	host: {
		class: 'icon',
	},
	templateUrl: './icon.component.html',
	styleUrls: ['./icon.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class IconComponent {
	@Input() name!: string;
	@Input() size!: string;
	@Input() rotate!: number;

	@HostBinding('style')
	get style(): string | void {
		const styles: string[] = [];

		if (this.rotate) styles.push(`--icon-rotation: ${this.rotate}deg;`);
		if (this.size) styles.push(`--icon-size: ${this.size};`);

		return styles.join(';');
	}
}
