import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DirectivesModule} from 'src/app/shared/directives/directives.module';
import {ButtonModule} from 'src/app/shared/components/button/button.module';
import {IconModule} from 'src/app/shared/components/icon/icon.module';

import {DrawerComponent} from './drawer.component';
import {DrawerBodyDirective} from './directives/drawer-body.directive';
import {DrawerFooterDirective} from './directives/drawer-footer.directive';
import {DrawerToggleDirective} from './directives/drawer-toggle.directive';
import {DrawerNavComponent} from './components/drawer-nav/drawer-nav.component';

const components = [
	DrawerComponent,
	DrawerBodyDirective,
	DrawerFooterDirective,
	DrawerToggleDirective,
	DrawerNavComponent,
];

@NgModule({
	imports: [CommonModule, DirectivesModule, ButtonModule, IconModule],
	declarations: [...components],
	exports: [...components],
})
export class DrawerModule {}
