import {Directive, Attribute} from '@angular/core';
import {Validator, NG_VALIDATORS, FormControl} from '@angular/forms';

@Directive({
	selector: '[compare]',
	providers: [{provide: NG_VALIDATORS, useExisting: CompareDirective, multi: true}],
})
export class CompareDirective implements Validator {
	constructor(@Attribute('compare') public comparer: string) {}

	public validate(control: FormControl): any {
		if (!control.root) return;

		const compare = control.root.get(this.comparer);

		if (!compare) return;

		if (compare.value !== control.value) {
			compare.setErrors({compare: true});

			return {
				compare: true,
			};
		}

		if (compare.value === control.value) {
			if (!compare.errors) return;

			delete compare.errors['compare'];

			if (!Object.keys(compare.errors).length) {
				compare.setErrors(null);
			}
		}
	}
}
