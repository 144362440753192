import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})
export class CacheService {
	private cache: Map<string, HttpResponse<any>> = new Map();

	public set(url: string, res: HttpResponse<any>) {
		const key = this.getKey(url);
		this.cache.set(key, res);
	}

	public get(url: string) {
		const key = this.getKey(url);
		return this.cache.get(key);
	}

	public delete(url: string) {
		const key = this.getKey(url);
		return this.cache.delete(key);
	}

	public deleteByIndex(str: string) {
		this.cache.forEach((val, key) => {
			if (key.indexOf(str) === -1) return;
			this.cache.delete(key);
		});
	}

	public deleteAllByIndex(arry: string[]) {
		arry.forEach((str) => this.deleteByIndex(str));
	}

	public clear() {
		this.cache.clear();
	}

	private getKey(url: string) {
		if (url.indexOf(environment.api) !== -1) {
			return url.replace(environment.api, '');
		}

		if (url.indexOf(environment.contentApi) !== -1) {
			return url.replace(environment.contentApi, '');
		}

		return url;
	}
}
