import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InputGroupComponent} from './input-group.component';

const components = [InputGroupComponent];

@NgModule({
	imports: [CommonModule],
	declarations: [...components],
	exports: [...components],
})
export class InputGroupModule {}
