import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {FilterMetadata, FilterOperator} from 'primeng/api';
import {DATE_MATCH_MODES, NUMBER_MATCH_MODES, SELECT_MATCH_MODES, TEXT_MATCH_MODES} from 'src/app/core';
import {MatchMode, TableColumnFilterType} from '../../models';
import {TableService} from '../../services/table.service';

@Component({
	selector: 'app-table-filter-form',
	host: {
		class: 'table-filter-form',
	},
	templateUrl: './table-filter-form.component.html',
	styleUrls: ['./table-filter-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TableFilterFormComponent implements OnInit {
	@Input() type!: TableColumnFilterType;
	@Input() field!: string;
	@Input() options$?: Observable<any[]>;
	@Input() optionsLabel?: string;
	@Input() optionsValue?: string;
	@Input() filters: FilterMetadata[] = [];

	@Output() filtersChange = new EventEmitter<FilterMetadata[]>();

	public matchModes!: MatchMode[];

	get isActive() {
		return this.filters.findIndex((f) => f.value) !== -1;
	}

	constructor(public tableService: TableService) {}

	public ngOnInit() {
		switch (this.type) {
			case 'date':
				this.matchModes = [...DATE_MATCH_MODES];
				break;

			case 'number':
				this.matchModes = [...NUMBER_MATCH_MODES];
				break;

			case 'select':
				this.matchModes = [...SELECT_MATCH_MODES];
				break;

			default:
				this.matchModes = [...TEXT_MATCH_MODES];
				break;
		}

		if (!this.filters.length) {
			this.filters.push(this.getMetadata());
			this.filtersChange.emit(this.filters);
		}
	}

	public addFilter() {
		this.filters.push(this.getMetadata());
		this.filtersChange.emit(this.filters);
	}

	public removeFilter(index: number) {
		this.filters.splice(index, 1);
		this.filtersChange.emit(this.filters);
	}

	public clearFilters() {
		this.filters.length = 0;
		this.filters.push(this.getMetadata());
		this.filtersChange.emit(this.filters);
	}

	private getMetadata(): FilterMetadata {
		return {
			value: null,
			matchMode: this.matchModes[0].code,
			operator: FilterOperator.AND,
		};
	}
}
