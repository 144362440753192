import {trigger, transition, query, animateChild, useAnimation, group} from '@angular/animations';
import {
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Renderer2,
	ViewEncapsulation,
} from '@angular/core';
import {uniqueId} from 'lodash-es';
import {fadeOut, fadeIn, slideInX, slideOutX, zoomOut, zoomIn} from 'src/app/core';
import {DrawerService} from './drawer.service';

// prettier-ignore
const drawerAnimation = trigger('drawerAnimation', [
	transition(
		':leave',
		group([
			query('.drawer__backdrop', animateChild()),
			query('.drawer__close', animateChild()),
			query('.drawer__dialog', animateChild())
		])
	),
	transition(
		':enter',
		group([
			query('.drawer__backdrop', animateChild()),
			query('.drawer__close', animateChild()),
			query('.drawer__dialog', animateChild())
		])
	)
]);

// prettier-ignore
const drawerBackdropAnimation = trigger('drawerBackdropAnimation', [
	transition(':leave', useAnimation(fadeOut, {params: {timings: '300ms 100ms ease-out'}})),
	transition(':enter', useAnimation(fadeIn, {params: {timings: '300ms ease-in'}}))
]);

// prettier-ignore
const drawerCloseAnimation = trigger('drawerCloseAnimation', [
	transition(':leave', useAnimation(zoomOut, {params: {timings: '350ms ease-out', scale: '0.6'}})),
	transition(':enter', useAnimation(zoomIn, {params: {timings: '250ms 400ms ease-in', scale: '0.6'}}))
]);

// prettier-ignore
const drawerDialogAnimation = trigger('drawerDialogAnimation', [
	transition(':leave', useAnimation(slideOutX, {params: {timings: '300ms ease-in-out', offset: 'calc(100% + 20px)'}})),
	transition(':enter', useAnimation(slideInX, {params: {timings: '300ms 100ms ease-in-out', offset: 'calc(100% + 20px)'}}))
]);

@Component({
	selector: 'app-drawer',
	templateUrl: './drawer.component.html',
	styleUrls: ['./drawer.component.scss'],
	animations: [drawerAnimation, drawerBackdropAnimation, drawerCloseAnimation, drawerDialogAnimation],
	encapsulation: ViewEncapsulation.None,
})
export class DrawerComponent implements OnInit, OnDestroy {
	@Input() id!: string;
	@Input() disabled = false;
	@Input() size: 'default' | 'fluid' = 'default';

	@Output() drawerWillOpen: EventEmitter<any> = new EventEmitter<any>();
	@Output() drawerDidOpen: EventEmitter<any> = new EventEmitter<any>();
	@Output() drawerWillClose: EventEmitter<any> = new EventEmitter<any>();
	@Output() drawerDidClose: EventEmitter<any> = new EventEmitter<any>();

	private el!: HTMLElement;
	public active = false;
	public state: 'opening' | 'open' | 'closing' | 'closed' = 'closed';
	public get classes(): string {
		const classes: string[] = [];

		classes.push(`-size-${this.size}`);

		return classes.join(' ');
	}

	constructor(private renderer: Renderer2, private elRef: ElementRef, private drawerService: DrawerService) {}

	public ngOnInit(): void {
		this.el = this.elRef.nativeElement;

		// Set ID
		if (!this.id) {
			this.id = uniqueId('drawer-');
			this.renderer.setAttribute(this.el, 'id', this.id);
		}

		// Set attributes
		this.renderer.setAttribute(this.el, 'role', 'dialog');
		this.renderer.setAttribute(this.el, 'tabindex', '-1');
		this.renderer.setAttribute(this.el, 'aria-hidden', 'true');

		// Add to modalService
		this.drawerService.add(this);

		// Move el to the bottom of page
		document.body.appendChild(this.el);
	}

	public ngOnDestroy(): void {
		this.drawerService.remove(this.id);
		this.el.remove();
	}

	@HostListener('document:keydown', ['$event'])
	public onEscClick(e: KeyboardEvent) {
		if (e.code === 'Escape') {
			this.close();
		}
	}

	public updateState(e: any) {
		if (e.fromState === 'void') {
			if (e.phaseName === 'start') {
				this.state = 'opening';
				this.drawerWillOpen.emit();
			} else {
				this.state = 'open';
				this.drawerDidOpen.emit();
			}
		} else {
			if (e.phaseName === 'start') {
				this.state = 'closing';
				this.drawerWillClose.emit();
			} else {
				this.state = 'closed';
				this.drawerDidClose.emit();
			}
		}
	}

	public async open(): Promise<void> {
		if (this.disabled || this.state === 'opening' || this.state === 'closing') return;

		this.active = true;
		this.renderer.setAttribute(this.el, 'aria-hidden', 'false');
		this.renderer.addClass(document.body, 'drawer-open');
	}

	public async close(): Promise<void> {
		if (this.disabled || this.state === 'opening' || this.state === 'closing') return;

		this.active = false;
		this.renderer.setAttribute(this.el, 'aria-hidden', 'true');
		this.renderer.removeClass(document.body, 'drawer-open');
	}

	public enable(): DrawerComponent {
		this.disabled = false;
		return this;
	}

	public disable(): DrawerComponent {
		this.disabled = true;
		return this;
	}
}
