import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {firstValueFrom} from 'rxjs';
import {UserService, UtilService} from 'src/app/core';

@Component({
	selector: 'page-reset-password',
	host: {class: 'page'},
	templateUrl: './reset-password.page.html',
	styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordPage implements OnInit {
	public loading = false;
	public username!: string;
	public password1!: string;
	public password2!: string;
	public message!: string;
	public token!: string;

	constructor(private route: ActivatedRoute, private userService: UserService, private util: UtilService) {}

	public ngOnInit(): void {
		const params = this.route.snapshot.queryParamMap;
		const token = params.get('reset_token');

		if (token) {
			this.token = token;
		}
	}

	public async requestPasswordReset(form: NgForm): Promise<void> {
		if (form.invalid || this.loading) return;

		this.loading = true;

		const res$ = this.userService.requestPasswordReset(this.username);
		const res = await firstValueFrom(res$);
		this.util.log(res);

		if (res === 'success') {
			this.message =
				'Thank-you, an email has been sent to your inbox. please follow the instructions to reset your password.';
		} else {
			this.message = 'We were unable to request a password reset for this account.';
		}

		this.loading = false;
	}

	public async resetPassword(form: NgForm): Promise<void> {
		if (form.invalid || this.loading) return;

		this.loading = true;

		const res$ = this.userService.resetPassword(this.password1, this.token);
		const res = await firstValueFrom(res$);
		this.util.log(res);

		if (res === 'success') {
			this.message = 'Thank-you, your password has been reset, click the link below to login.';
		} else {
			this.message = 'Something went wrong, please try again later.';
		}

		this.loading = false;
	}
}
