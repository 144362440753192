import {Injectable} from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class SearchBoxService {
	public searchTerms: string | undefined;

	constructor(private router: Router) {
		this.router.routeReuseStrategy.shouldReuseRoute = () => false;
		this.router.onSameUrlNavigation = 'reload';
	}

	public search(searchTerms: string): void {
		this.router.navigate(['/search'], {state: {searchTerms: searchTerms}});
		this.searchTerms = undefined;
	}
}
