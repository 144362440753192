import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SortablejsModule} from 'ngx-sortablejs';
import {IconModule} from '../icon/icon.module';

import {SortableListComponent} from './sortable-list.component';
import {SortableListHandleComponent} from './components/sortable-list-handle/sortable-list-handle.component';

const components = [SortableListComponent, SortableListHandleComponent];

@NgModule({
	imports: [CommonModule, SortablejsModule, IconModule],
	declarations: [...components],
	exports: [...components],
})
export class SortableListModule {}
