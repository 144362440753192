import {
	Component,
	ViewEncapsulation,
	Input,
	ElementRef,
	ContentChild,
	Output,
	EventEmitter,
	OnInit,
} from '@angular/core';
import {CardActionsDirective} from './directives/card-actions.directive';

@Component({
	selector: 'app-card',
	host: {
		'class': 'card',
		'[class.-link]': 'link',
		'[class.-actions]': 'actions',
		'[class.-selected]': 'selected',
		'[class.-inline]': 'inline',
	},
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CardComponent implements OnInit {
	@ContentChild(CardActionsDirective) actions!: CardActionsDirective;

	@Input() link: string | any[] | null | undefined;
	@Input() label!: string;
	@Input() disabled = false;
	@Input() selected = false;
	@Input() inline = false;

	@Output() cardClick: EventEmitter<any> = new EventEmitter<any>();

	public el!: HTMLElement;
	public type: 'link' | 'button' | undefined;

	constructor(public elRef: ElementRef) {}

	public ngOnInit(): void {
		this.el = this.elRef.nativeElement;

		if (this.link) {
			this.link === '#' ? (this.type = 'button') : (this.type = 'link');
		}
	}
}
