import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from 'src/app/shared/components/components.module';
import {DirectivesModule} from 'src/app/shared/directives/directives.module';
import {LayoutModule} from 'src/app/shared/layout/layout.module';
import {PipesModule} from 'src/app/shared/pipes/pipes.module';

const modules = [ComponentsModule, DirectivesModule, LayoutModule, PipesModule];

@NgModule({
	imports: [CommonModule, ...modules],
	exports: [...modules],
	declarations: [],
})
export class SharedModule {}
