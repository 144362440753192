import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared/shared.module';

import {LoginPageRoutingModule} from './login-routing.module';
import {LoginPage} from './login.page';

@NgModule({
	imports: [CommonModule, FormsModule, SharedModule, LoginPageRoutingModule],
	declarations: [LoginPage],
})
export class LoginPageModule {}
