import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared/shared.module';

import {ResetPasswordPageRoutingModule} from './reset-password-routing.module';
import {ResetPasswordPage} from './reset-password.page';

@NgModule({
	imports: [CommonModule, FormsModule, SharedModule, ResetPasswordPageRoutingModule],
	declarations: [ResetPasswordPage],
})
export class ResetPasswordPageModule {}
