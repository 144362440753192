import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {CardComponent} from './card.component';
import {CardActionsDirective} from './directives/card-actions.directive';
import {CardHeaderDirective} from './directives/card-header.directive';
import {CardContentDirective} from './directives/card-content.directive';
import {CardFooterDirective} from './directives/card-footer.directive';
import {CardTitleDirective} from './directives/card-title.directive';
import {CardSubTitleDirective} from './directives/card-sub-title.directive';

const components = [
	CardComponent,
	CardActionsDirective,
	CardHeaderDirective,
	CardContentDirective,
	CardFooterDirective,
	CardTitleDirective,
	CardSubTitleDirective,
];

@NgModule({
	imports: [CommonModule, RouterModule],
	declarations: [...components],
	exports: [...components],
})
export class CardModule {}
