import {Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';

type SpinnerTheme = 'primary' | 'success' | 'info' | 'warning' | 'danger';

@Component({
	selector: 'app-progress-spinner',
	host: {
		class: 'spinner',
	},
	template: `
		<div class="spinner__wrap">
			<svg class="spinner__svg" viewBox="25 25 50 50">
				<circle class="spinner__path" cx="50" cy="50" r="20" fill="none" stroke-miterlimit="10" />
			</svg>
		</div>
	`,
	styleUrls: ['./progress-spinner.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ProgressSpinnerComponent implements OnInit, OnChanges {
	@Input() theme: SpinnerTheme = 'primary';

	@HostBinding('class') classes!: string;

	constructor() {}

	public ngOnInit(): void {
		this.updateClasses();
	}

	public ngOnChanges(): void {
		this.updateClasses();
	}

	private updateClasses(): void {
		const classArray: string[] = [];

		classArray.push(`theme-${this.theme}`);

		this.classes = classArray.join(' ');
	}
}
