import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TableModule as PNGTable} from 'primeng/table';

// Modules
import {AccordionModule} from './accordion/accordion.module';
import {AlertModule} from './alert/alert.module';
import {ButtonModule} from './button/button.module';
import {ButtonGroupModule} from './button-group/button-group.module';
import {CardModule} from './card/card.module';
import {ChartModule} from './chart/chart.module';
import {CounterModule} from './counter/counter.module';
import {DatePickerModule} from './date-picker/date-picker.module';
import {DrawerModule} from './drawer/drawer.module';
import {DropdownModule} from './dropdown/dropdown.module';
import {EmbedModule} from './embed/embed.module';
import {IconModule} from './icon/icon.module';
import {InputModule} from './input/input.module';
import {InputDateModule} from './input-date/input-date.module';
import {InputGroupModule} from './input-group/input-group.module';
import {InputRadioModule} from './input-radio/input-radio.module';
import {InputRadioButtonsModule} from './input-radio-buttons/input-radio-buttons.module';
import {InputSelectModule} from './input-select/input-select.module';
import {InputSwitchModule} from './input-switch/input-switch.module';
import {ModalModule} from './modal/modal.module';
import {PanelModule} from './panel/panel.module';
import {ProgressBarModule} from './progress-bar/progress-bar.module';
import {ProgressSpinnerModule} from './progress-spinner/progress-spinner.module';
import {SortableListModule} from './sortable-list/sortable-list.module';
import {StatusIndicatorModule} from './status-indicator/status-indicator.module';
import {TableModule} from './table/table.module';
import {TabsModule} from './tabs/tabs.module';

const modules = [
	AccordionModule,
	AlertModule,
	ButtonModule,
	ButtonGroupModule,
	CardModule,
	ChartModule,
	CounterModule,
	DatePickerModule,
	DrawerModule,
	DropdownModule,
	EmbedModule,
	IconModule,
	InputModule,
	InputDateModule,
	InputGroupModule,
	InputRadioModule,
	InputRadioButtonsModule,
	InputSelectModule,
	InputSwitchModule,
	ModalModule,
	PanelModule,
	ProgressBarModule,
	ProgressSpinnerModule,
	SortableListModule,
	StatusIndicatorModule,
	TableModule,
	TabsModule,
];

@NgModule({
	imports: [CommonModule, RouterModule, PNGTable, ...modules],
	exports: [...modules],
	declarations: [],
})
export class ComponentsModule {}
