import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {uniqueId} from 'lodash-es';
import {UtilService} from 'src/app/core';

@Component({
	selector: 'app-input-select',
	host: {
		class: 'input-select',
	},
	templateUrl: './input-select.component.html',
	styleUrls: ['./input-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class InputSelectComponent implements OnInit {
	@Input() label!: string;
	@Input() placeholder?: string;
	@Input() value: any;

	@Output() valueChange = new EventEmitter<any>();

	public id!: string;

	constructor(public util: UtilService) {}

	public ngOnInit(): void {
		this.id = uniqueId('input-select-');
	}
}
