import {
	Component,
	Input,
	ElementRef,
	ViewEncapsulation,
	Renderer2,
	OnDestroy,
	OnInit,
	EventEmitter,
	Output,
	HostListener,
} from '@angular/core';
import {createPopper, Instance as PopperInstance, Placement as PopperPlacement} from '@popperjs/core';
import {uniqueId} from 'lodash-es';

// animations: [
// 	trigger('overlayAnimation', [
// 		transition(':enter', [
// 			style({opacity: 0, transform: 'scaleY(0.8)'}),
// 			animate('.12s cubic-bezier(0, 0, 0.2, 1)')
// 		]),
// 		transition(':leave', [
// 			animate('.1s linear', style({ opacity: 0 }))
// 		])
// 	])
// ],
@Component({
	selector: 'app-dropdown',
	host: {
		class: 'dropdown',
	},
	template: `
		<div class="dropdown__inner">
			<ng-content></ng-content>
		</div>
	`,
	styleUrls: ['./dropdown.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements OnInit, OnDestroy {
	@Input() tip = true;
	@Input() placement: PopperPlacement = 'bottom';
	@Input() offset = [0, 6];
	@Input() closeOnClickOutside = true;
	@Input() closeOnClickInside = false;
	@Input() relocate = true;

	@Output() dropdownWillOpen: EventEmitter<any> = new EventEmitter<any>();
	@Output() dropdownDidOpen: EventEmitter<any> = new EventEmitter<any>();
	@Output() dropdownWillClose: EventEmitter<any> = new EventEmitter<any>();
	@Output() dropdownDidClose: EventEmitter<any> = new EventEmitter<any>();

	public el!: HTMLElement;
	public id!: string;
	public popper!: PopperInstance;
	public isOpen = false;

	constructor(private renderer: Renderer2, private elRef: ElementRef) {}

	public ngOnInit() {
		this.el = this.elRef.nativeElement;

		if (!this.el.id) {
			this.id = uniqueId('dropdown-');
			this.renderer.setAttribute(this.el, 'id', this.id);
		} else {
			this.id = this.el.id;
		}

		if (this.relocate) {
			document.body.appendChild(this.el);
		}

		if (this.tip) {
			this.createTip();
		}
	}

	public ngOnDestroy() {
		this.el.remove();
	}

	@HostListener('click', ['$event'])
	public onClick(e: PointerEvent) {
		e.stopPropagation();

		if (this.closeOnClickInside) {
			this.close();
		}
	}

	public toggle(el: HTMLElement) {
		if (this.isOpen) {
			this.close();
		} else {
			this.open(el);
		}
	}

	public open(el: HTMLElement) {
		this.dropdownWillOpen.emit();
		this.isOpen = true;
		this.popper = createPopper(el, this.el, {
			placement: this.placement,
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: this.offset,
					},
				},
			],
		});
		this.renderer.addClass(this.el, '-menu-open');
		this.dropdownDidOpen.emit();
	}

	public close() {
		if (!this.popper) return;

		this.dropdownWillClose.emit();
		this.isOpen = false;
		this.popper.destroy();
		this.renderer.removeClass(this.el, '-menu-open');
		this.dropdownDidClose.emit();
	}

	private createTip() {
		const tipEl = document.createElement('span');
		tipEl.setAttribute('data-popper-arrow', '');
		tipEl.classList.add('dropdown__arrow');

		this.el.prepend(tipEl);
	}
}
