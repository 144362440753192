<app-content>
	<div id="wrapper">
		<figure id="logo">
			<app-icon name="logo"></app-icon>
			<p>T30</p>
		</figure>
		<app-card id="login">
			<form id="login-form" #form="ngForm" (ngSubmit)="login(form)" novalidate autocomplete="off">
				<app-alert *ngIf="error">
					<p>{{ error }}</p>
				</app-alert>

				<app-input>
					<label input-label i18n>Username</label>
					<input
						input-control
						type="text"
						name="username"
						[(ngModel)]="username"
						required
						autocomplete="username"
					/>
					<small input-error match="required">
						'<ng-container i18n>Username</ng-container>'
						<ng-container i18n>is a required field</ng-container>.
					</small>
				</app-input>

				<app-input>
					<label input-label i18n>Password</label>
					<input
						input-control
						type="password"
						name="password"
						[(ngModel)]="password"
						required
						autocomplete="current-password"
					/>
					<small input-error match="required">
						'<ng-container i18n>Password</ng-container>'
						<ng-container i18n>is a required field</ng-container>.
					</small>
				</app-input>

				<app-btn-group [block]="true">
					<button type="submit" btn theme="primary" shape="rounded" [loading]="loading">
						<ng-container i18n>Login</ng-container>
					</button>

					<a [routerLink]="['/reset-password']" btn theme="none" shape="rounded">
						<ng-container i18n>Forgot Password</ng-container>?
					</a>
				</app-btn-group>
			</form>
		</app-card>
	</div>
</app-content>
