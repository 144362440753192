import {Component, HostBinding, Input, OnChanges, OnInit, ViewEncapsulation} from '@angular/core';
import {EmbedSize} from './types';

@Component({
	selector: 'app-embed',
	host: {class: 'embed'},
	templateUrl: './embed.component.html',
	styleUrls: ['./embed.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class EmbedComponent implements OnInit, OnChanges {
	@Input() ratio!: EmbedSize;
	@Input() loading = false;

	@HostBinding('class') classes!: string;

	constructor() {}

	public ngOnInit(): void {
		this.updateClasses();
	}

	public ngOnChanges(): void {
		this.updateClasses();
	}

	private updateClasses(): void {
		const classArray: string[] = [];

		classArray.push(`-ratio-${this.ratio}`);

		this.classes = classArray.join(' ');
	}
}
