import {Component, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {HeaderLink} from 'src/app/models';

@Component({
	selector: 'app-header',
	host: {
		class: 'page-header',
	},
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
	@Input() back: string | any[] | null | undefined;
	@Input() links: HeaderLink[] | undefined;

	public unsubscribe$: Subject<boolean> = new Subject();
	public url: string | undefined;

	constructor(private router: Router) {}

	public ngOnInit(): void {
		this.url = this.router.url;

		this.router.events
			.pipe(
				takeUntil(this.unsubscribe$),
				filter((event) => event instanceof NavigationEnd),
			)
			.subscribe((data: any) => {
				this.url = data.url;
			});
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	public navigateTo(path: string): void {
		this.router.navigateByUrl(path);
	}

	public isSelected(link: HeaderLink): boolean {
		return link.path === this.url;
	}
}
