import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	OnDestroy,
	OnInit,
	Optional,
	Output,
} from '@angular/core';
import {NgModel, NgControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
	selector: 'textarea[autoresize]',
})
export class AutoResizeDirective implements OnInit, AfterViewInit, OnDestroy {
	@Output() textareaResize: EventEmitter<any> = new EventEmitter();

	public el!: HTMLTextAreaElement;
	public filled = false;
	public minHeight!: number;
	public unsubscribe$: Subject<boolean> = new Subject();

	constructor(
		public elRef: ElementRef,
		@Optional() public ngModel: NgModel,
		@Optional() public control: NgControl,
		private cd: ChangeDetectorRef,
	) {}

	public ngOnInit(): void {
		this.el = this.elRef.nativeElement;

		if (this.ngModel) {
			this.ngModel.valueChanges?.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
				this.resize();
			});
		}

		if (this.control) {
			this.control.valueChanges?.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
				this.resize();
			});
		}
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	public ngAfterViewInit(): void {
		this.el.rows = 1;
		this.minHeight = this.el.offsetHeight;

		this.resize();
		this.cd.detectChanges();
	}

	@HostListener('input', ['$event'])
	public onInput(e: Event) {
		this.resize(e);
	}

	@HostListener('focus', ['$event'])
	public onFocus(e: Event) {
		this.resize(e);
	}

	@HostListener('blur', ['$event'])
	public onBlur(e: Event) {
		this.resize(e);
	}

	private resize(event?: Event) {
		this.filled = (this.el.value && this.el.value.length) || (this.ngModel && this.ngModel.model);
		this.el.style.height = 'auto';

		if (this.el.scrollHeight > 0) {
			this.el.style.height = this.el.scrollHeight + 'px';
		}

		if (parseFloat(this.el.style.height) >= parseFloat(this.el.style.maxHeight)) {
			this.el.style.overflowY = 'scroll';
			this.el.style.height = this.el.style.maxHeight;
		} else {
			this.el.style.overflow = 'hidden';
		}

		this.textareaResize.emit(event || {});
	}
}
