import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ProgressSpinnerComponent} from './progress-spinner.component';

const components = [ProgressSpinnerComponent];

@NgModule({
	imports: [CommonModule],
	declarations: [...components],
	exports: [...components],
})
export class ProgressSpinnerModule {}
