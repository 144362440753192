import {Pipe, PipeTransform} from '@angular/core';
import {filter, get, isArray, isObject} from 'lodash-es';

@Pipe({
	name: 'filterBy',
	pure: false,
})
export class FilterByPipe implements PipeTransform {
	transform<T>(collection: T[] | null, prop: string, val: string | number | boolean | undefined): T[] | null {
		if (!collection || !val) return collection;

		if (!isArray(collection)) {
			throw new Error(`FilterByPipe: Collection is not iterable.`);
		}

		if (!isObject(collection[0])) {
			throw new Error(`FilterByPipe: Collection items are not an object.`);
		}

		if (!get(collection[0], prop)) {
			throw new Error(`FilterByPipe: Property "${prop}" does not exist on collection items.`);
		}

		const results = filter(collection, (item) => get(item, prop) === val);

		return results;
	}
}
