import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

interface NavEvent {
	type: 'prev' | 'next';
	item: any;
}

@Component({
	selector: 'app-drawer-nav',
	host: {
		class: 'drawer-nav',
	},
	templateUrl: './drawer-nav.component.html',
	styleUrls: ['./drawer-nav.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DrawerNavComponent {
	@Input() item!: any | undefined;
	@Input() collection!: any[] | undefined;
	@Input() disabled = false;

	@Output() drawerNavigate = new EventEmitter<NavEvent>();

	constructor() {}

	public isFirstItem(): boolean {
		if (!this.collection) return true;

		const index = this.getItemIndex();
		return index <= 0;
	}

	public isLastItem(): boolean {
		if (!this.collection) return true;

		// const items = this.collection$.getValue();
		const index = this.getItemIndex();
		return index >= this.collection.length - 1;
	}

	public prev(): void {
		if (!this.collection) return;

		const index = this.getItemIndex();
		const prevIndex = index - 1;
		// const items = this.collection$.getValue();

		if (prevIndex >= 0) {
			this.drawerNavigate.emit({
				type: 'prev',
				item: this.collection[prevIndex],
			});
		}
	}

	public next(): void {
		if (!this.collection) return;

		const index = this.getItemIndex();
		const nextIndex = index + 1;
		// const items = this.collection$.getValue();

		if (this.collection.length > nextIndex) {
			this.drawerNavigate.emit({
				type: 'next',
				item: this.collection[nextIndex],
			});
		}
	}

	private getItemIndex(): number {
		if (!this.collection || !this.item) return -1;

		// const item = this.item$.getValue();
		// const items = this.collection$.getValue();
		return this.collection.findIndex((i) => i === this.item);
	}
}
