import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {share, tap} from 'rxjs/operators';
import {CacheService} from 'src/app/core';

@Injectable({
	providedIn: 'root',
})
export class CacheInterceptor implements HttpInterceptor {
	constructor(private cache: CacheService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (req.method !== 'GET') {
			return next.handle(req);
		}

		if (req.headers.get('no-cache')) {
			this.cache.delete(req.url);
		}

		const cachedResponse: HttpResponse<any> | undefined = this.cache.get(req.url);

		if (cachedResponse) {
			return of(cachedResponse.clone());
		}

		return next.handle(req).pipe(
			tap((stateEvent) => {
				if (stateEvent instanceof HttpResponse) {
					this.cache.set(req.url, stateEvent.clone());
				}
			}),
			share(),
		);
	}
}
