import {AfterContentInit, Component, ElementRef, Input, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {uniqueId} from 'lodash-es';

@Component({
	selector: 'app-input-switch',
	host: {
		class: 'input-switch',
	},
	templateUrl: './input-switch.component.html',
	styleUrls: ['./input-switch.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class InputSwitchComponent implements OnInit, AfterContentInit {
	@Input() labelOn: string | undefined;
	@Input() labelOff: string | undefined;

	public id!: string;
	public el!: HTMLElement;
	public input!: HTMLInputElement | null;

	constructor(private elRef: ElementRef, private renderer: Renderer2) {}

	public ngOnInit(): void {
		this.el = this.elRef.nativeElement;
	}

	public ngAfterContentInit(): void {
		this.id = uniqueId('switch-');
		this.input = this.el.querySelector('input');

		if (!this.input) {
			throw new Error('No input provided!');
		}

		this.renderer.setAttribute(this.input, 'id', this.id);
	}
}
