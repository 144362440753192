import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
import {ProgressSpinnerModule} from 'src/app/shared/components/progress-spinner/progress-spinner.module';

import {InputDateComponent} from './input-date.component';

const components = [InputDateComponent];

@NgModule({
	imports: [CommonModule, IconModule, ProgressSpinnerModule],
	declarations: [...components],
	exports: [...components],
})
export class InputDateModule {}
