<!-- prettier-ignore -->
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	<defs>
		<!-- Logo -->
		<symbol id="svg-logo" viewBox="0 0 100.7 101.6">
			<path stroke="none" d="m78.2 71.7c1.2 0 1.6.6 1.6 1.8 0 1.3-.4 1.9-1.6 1.9h-.6v-3.7zm-28.9.1h-.5v5.3h.5c1.7 0 1.6-1 1.6-2.7s.1-2.6-1.6-2.6m13.4.7-.9 5.4h1.9zm30.9-4.5h-6.9v3.2c.7.4.6 1.1.6 1.8v8.2c0 .7.1 1.4-.6 1.8v3.2h6.9v-3.2c-.7-.4-.6-1.1-.6-1.8v-8.2c0-.7-.1-1.4.6-1.8zm-7.7 15c-.7-.3-.7-1.2-.7-1.8v-1c0-1.9-.6-2.6-2.4-3 2.1-.5 2.7-2.3 2.7-4.3 0-1.7-.5-3.2-2-4.1-1.2-.7-3-.7-4.4-.7h-7.9v3.2c.7.4.6 1.1.6 1.8v8.2c0 .7.1 1.4-.6 1.8v3.2h6.9v-3.3c-.7-.4-.6-1.1-.6-1.8v-2.1h.2c1.6 0 1.7.8 1.7 2.2-.1 3 .1 3.9.3 4.9h6.1v-3.2zm-15.1 0c-.6-.2-.9-1.2-1-1.8l-2.6-13.2h-8.1l-2.4 12.9c-.2.7-.2 1.6-.8 2.1v3.2h6.3v-3.2c-.5-.1-.7-.5-.7-1 0-.2 0-.4.1-.5h2.7c0 .2.1.3.1.5 0 .5-.2.8-.7 1v3.2h7.2v-3.2zm-20-2c4.7 0 5.8-2.1 5.8-5.6v-1.4c0-.5.3-3.4-1.8-5-1.3-1-3.4-1-4.9-1h-7.4v3.2c.7.4.6 1.1.6 1.8v8.2c0 .7.1 1.4-.6 1.8v3.2h6.9v-3.2c-.7-.4-.6-1.1-.6-2zm-13.9-13h-13.6v3.2c.7.4.6 1.1.6 1.8v8.2c0 .7.1 1.4-.6 1.8v3.2h13.6v-6.6h-5.2v.6c0 1.5 0 2.1-1.7 2.1h-.3v-3.7h3.6v-3.6h-3.6v-3.3h.3c1.5 0 1.7.5 1.7 1.7v.6h5.2zm-14.1 0h-15.7v8.2h4v-3.1c0-.8.2-1.3 1-1.3v9.3c0 .7.1 1.4-.6 1.8v3.2h6.9v-3.1c-.7-.4-.6-1.1-.6-1.8v-9.3c.8 0 1 .5 1 1.3v3.1h4zm77.9-50.1v83.6h-100.7v-101.5h82.8z" />
		</symbol>

		<!-- Arrow Small -->
		<symbol id="svg-arrow-small" viewBox="0 0 30 30">
			<path d="m15 22.5-10-15h20z" stroke="none" />
		</symbol>

		<!-- Arrow -->
		<symbol id="svg-arrow" viewBox="0 0 30 30">
			<g fill="none" stroke-linecap="round" stroke-linejoin="round">
				<path d="m5 15h18.1" />
				<path d="m19.2 20.8 5.8-5.8" />
				<path d="m19.2 9.2 5.8 5.8" />
			</g>
		</symbol>

		<!-- Arrows -->
		<symbol id="svg-arrows" viewBox="0 0 30 30">
			<g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<circle cx="7.5" cy="6.2" r="2.5" />
				<circle cx="22.5" cy="23.8" r="2.5" />
				<path fill="none" d="m20 23.8h-6.2c-3.5 0-6.3-2.8-6.3-6.2v-3.8l3.8 3.8m-7.5-.1 3.8-3.8" />
				<path fill="none" d="m10 6.3h6.2c3.5 0 6.2 2.8 6.2 6.2v3.8l3.8-3.8m-7.4 0 3.8 3.8" />
			</g>
		</symbol>

		<!-- Bell -->
		<symbol id="svg-bell" viewBox="0 0 30 30">
			<g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m11.8 20.3v1.1c0 1.8 1.4 3.2 3.2 3.2s3.2-1.4 3.2-3.2v-1.1" />
				<path d="m12.9 7.5c0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1 2.5 1.2 4.1 3.6 4.3 6.4v3.2c.2 1.3 1 2.5 2.1 3.2h-17c1.2-.7 2-1.9 2.1-3.2v-3.2c.1-2.7 1.8-5.2 4.3-6.4" />
			</g>
		</symbol>

		<!-- Calendar -->
		<symbol id="svg-calendar" viewBox="0 0 30 30">
			<g fill="none" stroke-linecap="round" stroke-linejoin="round">
				<path d="m11 7.2h8c2.7 0 4.9 2.2 4.9 4.9v8c0 2.7-2.2 4.9-4.9 4.9h-8c-2.7 0-4.9-2.2-4.9-4.9v-8c0-2.7 2.2-4.9 4.9-4.9z" />
				<path d="m19.4 5v4.4" />
				<path d="m10.6 5v4.4" />
				<path d="m6.1 13.9h17.8" />
				<path d="m12.8 19.4h4.4" />
				<path d="m15 17.2v4.4" />
			</g>
		</symbol>

		<!-- Card View -->
		<symbol id="svg-card-view" viewBox="0 0 30 30">
			<g stroke="none">
				<path d="m5 6.3h4v4h-4z" />
				<path d="m5 13h4v4h-4z" />
				<path d="m5 19.7h4v4h-4z" />
				<path d="m13 6.3h4v4h-4z" />
				<path d="m13 13h4v4h-4z" />
				<path d="m13 19.7h4v4h-4z" />
				<path d="m21 6.3h4v4h-4z" />
				<path d="m21 13h4v4h-4z" />
				<path d="m21 19.7h4v4h-4z" />
			</g>
		</symbol>

		<!-- Close -->
		<symbol id="svg-close" viewBox="0 0 30 30">
			<path d="m5 5 20 20m0-20-20 20" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" />
		</symbol>

		<!-- Danger -->
		<symbol id="svg-danger" viewBox="0 0 30 30">
			<g stroke="none">
				<path d="m16.8 2.9c-.6-1-1.9-1.4-2.9-.8-.3.2-.6.5-.8.8l-12.8 21.9c-.9 1.5.2 3.3 1.8 3.3h25.7c1.7 0 2.7-1.9 1.8-3.3zm-1.8 6.5c1 0 1.8.9 1.7 1.9l-.7 6.5c0 .6-.5 1-1.1.9-.5 0-.9-.4-.9-.9l-.7-6.6c-.1-.9.6-1.8 1.5-1.9.1.1.1.1.2.1zm0 11.2c1 0 1.9.8 1.9 1.9 0 1-.8 1.9-1.9 1.9s-1.9-.8-1.9-1.9c0-1 .9-1.9 1.9-1.9z"/>
			</g>
		</symbol>

		<!-- Doc Add -->
		<symbol id="svg-doc-add" viewBox="0 0 30 30">
			<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m21.2 26.2h-12.4c-1.4 0-2.5-1.1-2.5-2.5v-17.5c0-1.4 1.1-2.5 2.5-2.5h8.8l6.2 6.2v13.8c0 1.4-1.2 2.5-2.6 2.5z" />
				<path d="m17.5 3.8v5c0 .7.6 1.2 1.2 1.2h5" />
				<path d="m15 13.8v7.4" />
				<path d="m11.2 17.5h7.6" />
			</g>
		</symbol>

		<!-- Edit -->
		<symbol id="svg-edit" viewBox="0 0 30 30">
			<path clip-rule="evenodd" d="m5 23.6h20v1.4h-20zm18.1-13.6c.6-.5.6-1.4 0-2l-2.6-2.6c-.5-.6-1.4-.6-2 0l-12.1 12.2v4.6h4.6zm-3.5-3.6 2.5 2.6-2.1 2.1-2.6-2.6zm-11.7 14.3v-2.6l8.6-8.6 2.6 2.6-8.6 8.6z" stroke="none" fill-rule="evenodd" />
		</symbol>

		<!-- Filter -->
		<symbol id="svg-filter" viewBox="0 0 30 30">
			<path d="m5.9 5.2h18.1c.7.3 1.1 1 .9 1.8 0 .1-.1.2-.2.3l-7 7.7v9.8l-5.5-4.3v-5.6l-7-7.7c-.4-.6-.2-1.4.4-1.9.1 0 .2-.1.3-.1" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</symbol>

		<!-- Gear -->
		<symbol id="svg-gear" viewBox="0 0 30 30">
			<path stroke="none" d="m13.5 5c-.1 0-.2.1-.2.2l-.6 2.3c-.5.1-.9.3-1.4.6l-2.1-1.2c0-.1-.2-.1-.2 0l-2.1 2.1c-.1 0-.1.2 0 .2l1.2 2.1c-.2.4-.4.9-.6 1.4l-2.3.6c-.1 0-.2.1-.2.2v2.9c0 .1.1.2.2.2l2.3.6c.1.5.3.9.6 1.4l-1.2 2.1c-.1.1-.1.2 0 .3l2.1 2.1c.1.1.2.1.3 0l2.1-1.2c.4.2.9.4 1.4.6l.6 2.3c0 .1.1.2.2.2h2.9c.1 0 .2-.1.2-.2l.6-2.3c.5-.1.9-.3 1.4-.6l2.1 1.2c.1.1.2.1.3 0l2.1-2.1c.1-.1.1-.2 0-.3l-1.2-2.1c.2-.4.4-.9.6-1.4l2.3-.6c.1 0 .2-.1.2-.2v-2.9c0-.1-.1-.2-.2-.2l-2.3-.6c-.1-.5-.3-1-.6-1.4l1.2-2.1c.1-.1.1-.2 0-.3l-2.2-2c-.1-.1-.2-.1-.3 0l-2.1 1.2c-.4-.2-.9-.4-1.4-.6l-.6-2.3c0-.1-.1-.2-.2-.2zm1.5 6c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4c0-2.2 1.8-4 4-4z" />
		</symbol>

		<!-- Graph -->
		<symbol id="svg-graph" viewBox="0 0 30 30">
			<g fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
				<path d="m5 15.1h6.7v8.8h-6.7z"/>
				<path d="m11.6 10.6h6.7v13.3h-6.7z"/>
				<path d="m18.3 6.1h6.7v17.8h-6.7z"/>
			</g>
		</symbol>

		<!-- Handle -->
		<symbol id="svg-handle" viewBox="0 0 30 30">
			<g stroke="none">
				<circle cx="15" cy="4.55" r="4.5" />
				<circle cx="15" cy="25.55" r="4.5"/>
				<circle cx="4.5" cy="14.95" r="4.5"/>
				<circle cx="25.5" cy="14.95" r="4.5"/>
			</g>
		</symbol>

		<!-- Info -->
		<symbol id="svg-info" viewBox="0 0 30 30">
			<g stroke="none">
				<path d="m15 30c8.3 0 15-6.7 15-15s-6.7-15-15-15-15 6.7-15 15 6.7 15 15 15zm1.7-17.6-1.9 8.8c-.1.6.1 1 .6 1 .4 0 .9-.1 1.3-.5l-.2.8c-.5.6-1.7 1.1-2.7 1.1-1.3 0-1.9-.8-1.5-2.5l1.4-6.5c.1-.5 0-.7-.5-.9l-.8-.2.2-.7zm-1.7-2.1c-1 0-1.9-.8-1.9-1.9s.9-1.8 1.9-1.8 1.9.8 1.9 1.9-.9 1.8-1.9 1.8z"/>
			</g>
		</symbol>

		<!-- Lightning -->
		<symbol id="svg-lightning" viewBox="0 0 30 30">
			<path
				  d="m16.3 3.8v8.8h7.5l-10 13.8v-8.8h-7.5z"
				  stroke-linecap="round"
				  stroke-linejoin="round"
				  stroke-width="2" />
		</symbol>

		<!-- List View -->
		<symbol id="svg-list-view" viewBox="0 0 30 30">
			<g stroke="none">
				<path d="m5 6.3h20v4h-20z" />
				<path d="m5 19.7h20v4h-20z" />
				<path d="m5 13h20v4h-20z" />
			</g>
		</symbol>

		<!-- Logout -->
		<symbol id="svg-logout" viewBox="0 0 30 30">
			<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m17.5 10v-2.5c0-1.4-1.1-2.5-2.5-2.5h-8.8c-1.4 0-2.4 1.1-2.4 2.5v15c0 1.4 1.1 2.5 2.5 2.5h8.7c1.4 0 2.5-1.1 2.5-2.5v-2.5" />
				<path d="m8.7 15h17.5l-3.8-3.8m.1 7.6 3.8-3.8" />
			</g>
		</symbol>

		<!-- Play -->
		<symbol id="svg-play" viewBox="0 0 30 30">
			<path d="m13.6 17.9 4.4-2.9-4.4-2.9z" fill="#fff" />
			<g fill="none">
				<circle cx="15" cy="15" r="10" />
				<circle cx="15" cy="15" r="9.5" stroke="#fff" stroke-width="2" />
			</g>
		</symbol>

		<!-- Plus -->
		<symbol id="svg-plus" viewBox="0 0 30 30">
			<g fill="none" stroke-width="4">
				<path d="m15 5v20" />
				<path d="m25 15h-20" />
			</g>
		</symbol>

		<!-- Print -->
		<symbol id="svg-print" viewBox="0 0 30 30">
			<g fill="none" stroke-linecap="round" stroke-linejoin="round">
				<path d="m10.8 18.1h8.4v6.2h-8.4z" />
				<path d="m19.2 21.2h3.6c1.2 0 2.2-1 2.2-2.2v-4.4c0-1.2-1-2.2-2.2-2.2h-15.6c-1.2 0-2.2 1-2.2 2.2v4.4c0 1.2 1 2.2 2.2 2.2h3.6" />
				<path d="m20.6 12.3v-4.4c0-1.2-1-2.2-2.2-2.2h-6.7c-1.2 0-2.2 1-2.2 2.2v4.4" />
			</g>
		</symbol>

		<!-- Scales -->
		<symbol id="svg-scales" viewBox="0 0 30 30">
			<g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m15 3.8v22.4" />
				<path d="m11.3 26.2h7.5" />
				<path d="m3.7 7.5 3.8 1.2 7.5-2.5 7.5 2.5 3.8-1.2" />
				<path d="m7.5 8.8-3.8 11.2c2.2 1.7 5.3 1.7 7.5 0z" />
				<path d="m22.5 8.8-3.8 11.2c2.2 1.7 5.3 1.7 7.5 0z" />
			</g>
		</symbol>

		<!-- Search -->
		<symbol id="svg-search" viewBox="0 0 30 30">
			<g fill="none" stroke-width="2">
				<circle cx="12.8" cy="12.3" r="7.3" />
				<path d="m17.8 17.7 6.7 7.3" />
			</g>
		</symbol>

		<!-- Sort -->
		<symbol id="svg-sort" viewBox="0 0 30 30">
			<g stroke="none">
				<path d="m15 24-5.6-8.3h11.2zm8.8-13.2h-17.6l-3.2-4.8h24z"/>
			</g>
			<!--
			<g stroke="none">
				<path d="m15 5 4 6.7h-8z" />
				<path d="m15 25-4-6.7h8z" />
			</g>
			-->
		</symbol>

		<!-- Squares -->
		<symbol id="svg-squares" viewBox="0 0 30 30">
			<g stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m6.8 5h3.8c1 0 1.8.8 1.8 1.8v3.8c0 1-.8 1.8-1.8 1.8h-3.8c-1 0-1.8-.8-1.8-1.8v-3.8c0-1 .8-1.8 1.8-1.8z" />
				<path d="m19.3 5h3.8c1 0 1.8.8 1.8 1.8v3.8c0 1-.8 1.8-1.8 1.8h-3.8c-1 0-1.8-.8-1.8-1.8v-3.8c0-1 .8-1.8 1.8-1.8z" />
				<path d="m6.8 17.5h3.8c1 0 1.8.8 1.8 1.8v3.8c0 1-.8 1.8-1.8 1.8h-3.8c-1 0-1.8-.8-1.8-1.8v-3.8c0-1 .8-1.8 1.8-1.8z" />
				<path d="m19.3 17.5h3.8c1 0 1.8.8 1.8 1.8v3.8c0 1-.8 1.8-1.8 1.8h-3.8c-1 0-1.8-.8-1.8-1.8v-3.8c0-1 .8-1.8 1.8-1.8z" />
			</g>
		</symbol>

		<!-- Success -->
		<symbol id="svg-success" viewBox="0 0 30 30">
			<g stroke="none">
				<path d="m30 15c0 8.3-6.7 15-15 15s-15-6.7-15-15 6.7-15 15-15 15 6.7 15 15zm-7.4-5.7c-.6-.5-1.4-.5-2 0l-6.6 8.4-3.9-3.9c-.6-.5-1.5-.5-2 .1-.5.5-.5 1.4 0 1.9l5 5c.5.5 1.4.5 2 0l7.5-9.4c.5-.7.5-1.5 0-2.1z"/>
			</g>
		</symbol>

		<!-- Trash -->
		<symbol id="svg-trash" viewBox="0 0 30 30">
			<g fill="none" stroke-width="2">
				<path d="m20.2 24.3h-10.4c-.2 0-.4-.2-.4-.4l-.7-13.8h12.7l-.8 13.8c0 .2-.2.4-.4.4z"/>
				<path d="m22.3 10.1h-14.6c-.1 0-.2-.1-.2-.1l.4-1.7c0-.1.1-.1.2-.1h13.9c.1 0 .2 0 .2.1l.4 1.7c-.1 0-.2.1-.3.1z"/>
				<path d="m11.9 12.2v9.6"/>
				<path d="m15 12.2v9.6"/>
				<path d="m18.1 12.2v9.6"/>
				<path d="m12.6 8.1v-2c0-.2.2-.4.4-.4h4c.2 0 .4.2.4.4v2"/>
			</g>
		</symbol>

		<!-- User -->
		<symbol id="svg-user" viewBox="0 0 30 30">
			<g fill="none" stroke-linejoin="round" stroke-width="2">
				<circle cx="15" cy="8.8" r="5" stroke-linecap="round" />
				<path d="m7.5 26.2v-2.5c0-2.8 2.2-5 5-5h5c2.8 0 5 2.2 5 5v2.5" />
			</g>
		</symbol>

		<!-- Vile -->
		<symbol id="svg-vile" viewBox="0 0 30 30">
			<path d="m20.2 12.5-11.9 11.7c-1.1 1-2.7 1-3.8-.1-1-1-1-2.7 0-3.7l7.9-8" />
			<g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
				<path d="m20 5 3.8 3.8-15.5 15.4c-1.1 1-2.7 1-3.8-.1-1-1-1-2.7 0-3.7z" />
				<path d="m12.5 12.5h7.5" />
				<path d="m23.8 18.8 1.9 2c.9 1 .8 2.6-.2 3.5s-2.6.8-3.5-.2c-.8-.9-.8-2.4 0-3.3z" />
			</g>
		</symbol>

		<!-- Warning -->
		<symbol id="svg-warning" viewBox="0 0 30 30">
			<g stroke="none">
				<path d="m30 15c0 8.3-6.7 15-15 15s-15-6.7-15-15 6.7-15 15-15 15 6.7 15 15zm-15-7.5c-.9 0-1.7.8-1.7 1.7v.2l.7 6.6c0 .6.5 1 1.1.9.5 0 .9-.4.9-.9l.7-6.6c.1-.9-.6-1.8-1.5-1.9-.1 0-.1 0-.2 0zm0 11.3c-1 0-1.9.8-1.9 1.9s.8 1.9 1.9 1.9 1.9-.8 1.9-1.9-.9-1.9-1.9-1.9z"/>
			</g>
		</symbol>
	</defs>
</svg>
