import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
	name: 'padStart',
})
export class PadStartPipe implements PipeTransform {
	transform(value: string | number, length: number, fillString?: string | undefined): string | number {
		if (!value && value !== 0) return value;

		if (typeof value !== 'string') {
			value = value.toString();
		}

		return value.padStart(length, fillString);
	}
}
