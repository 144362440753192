import {
	AfterContentInit,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Optional,
	Output,
	Renderer2,
	Self,
} from '@angular/core';
import {AbstractControl, NgControl, ValidationErrors} from '@angular/forms';

@Directive({
	selector: '[input-control]',
	host: {
		'class': 'input__control',
		'[attr.readonly]': `readonly`,
		'[attr.aria-invalid]': `ngControl && ngControl.control.invalid`,
		'[attr.aria-required]': `hasValidator('required')`,
	},
})
export class InputControlDirective implements AfterContentInit {
	public el: HTMLElement;
	public isFocused = false;

	@Input() name = '';
	@Input() readonly: string | null = null;
	@Input() disabled: string | boolean | null = null;

	@Output() inputChange: EventEmitter<string | number> = new EventEmitter<string | number>();

	constructor(
		public elRef: ElementRef,
		private renderer: Renderer2,
		@Self() @Optional() public ngControl?: NgControl,
	) {
		this.el = this.elRef.nativeElement;
	}

	ngAfterContentInit(): void {
		if (this.isReadonly()) {
			this.renderer.setAttribute(this.el, 'tabindex', '-1');
		}
	}

	@HostListener('ngModelChange', ['$event'])
	public onModelChange(value: string | number): void {
		this.inputChange.emit(value);
	}

	@HostListener('focus', ['$event'])
	public onFocus(): void {
		if (this.isReadonly() || this.isDisabled()) return;

		this.isFocused = true;
	}

	@HostListener('blur', ['$event'])
	public onBlur(): void {
		if (this.isReadonly() || this.isDisabled()) return;

		this.isFocused = false;
	}

	public getErrors(): ValidationErrors | null {
		if (this.ngControl && this.ngControl.errors) {
			return this.ngControl.errors;
		} else {
			return null;
		}
	}

	public hasProp(prop: keyof NgControl): boolean {
		if (this.ngControl && this.ngControl[prop]) {
			return true;
		} else {
			return false;
		}
	}

	public hasValidator(property: string): boolean {
		if (this.ngControl && this.ngControl.validator) {
			const control = this.ngControl.validator({} as AbstractControl);
			return this.hasProperty(control, property);
		} else {
			return false;
		}
	}

	private hasProperty(control: Record<string, unknown> | null, property: string) {
		if (control) {
			return Object.prototype.hasOwnProperty.call(control, property);
		} else {
			return false;
		}
	}

	private isDisabled(): boolean {
		return this.disabled !== null;
	}

	private isReadonly(): boolean {
		return this.readonly !== null;
	}
}
