import {Directive, ElementRef} from '@angular/core';

@Directive({
	selector: 'app-card-actions, [card-actions]',
	host: {class: 'card__actions'},
})
export class CardActionsDirective {
	public el: HTMLElement;

	constructor(public elRef: ElementRef) {
		this.el = this.elRef.nativeElement;
	}
}
