import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {SessionService} from 'src/app/core';

@Component({
	selector: 'page-login',
	host: {class: 'page'},
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
	public username!: string;
	public password!: string;
	public error!: string;
	public loading = false;

	constructor(private activeRoute: ActivatedRoute, private router: Router, private session: SessionService) {}

	public ngOnInit(): void {
		this.activeRoute.queryParams.subscribe(async (params) => {
			this.username = params.u || '';
			this.password = params.p || '';

			if (this.username && this.password) {
				this.login({invalid: false} as NgForm);
			}
		});
	}

	public async login(form: NgForm): Promise<void> {
		if (form.invalid || this.loading) return;

		this.loading = true;

		this.session.login(this.username, this.password).subscribe(
			(res) => {
				if (res.status === 'success') {
					this.router.navigate(['/animals/mobs']);
				} else {
					this.error = 'something went wrong';
					this.loading = false;
				}
			},
			(res) => {
				this.error = res.error.error;
				this.loading = false;
			},
		);
	}
}
