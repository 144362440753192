import {Injectable} from '@angular/core';
import {UtilService} from 'src/app/core';
import {ModalComponent} from './modal.component';

@Injectable({
	providedIn: 'root',
})
export class ModalService {
	private modals: ModalComponent[] = [];

	constructor(private util: UtilService) {}

	public add(modal: ModalComponent): void {
		this.modals.push(modal);
	}

	public remove(id: string): void {
		this.modals = this.modals.filter((x) => x.id !== id);
	}

	public async toggle(id: string): Promise<void> {
		const modal = this.modals.find((x) => x.id === id);

		if (!modal) {
			this.util.error(`Modal "${id}" could not be found.`);
			return;
		}

		if (modal.state === 'closed') {
			await modal.open();
		} else {
			await modal.close();
		}
	}

	public async open(id: string): Promise<void> {
		const modal = this.modals.find((x) => x.id === id);

		if (modal) {
			await modal.open();
		} else {
			this.util.error(`Modal "${id}" could not be found.`);
		}
	}

	public async close(id: string): Promise<void> {
		const modal = this.modals.find((x) => x.id === id);

		if (modal) {
			await modal.close();
		} else {
			this.util.error(`Modal "${id}" could not be found.`);
		}
	}
}
