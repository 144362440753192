import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
import {InputDateModule} from 'src/app/shared/components/input-date/input-date.module';

import {DatePickerComponent} from './date-picker.component';

const components = [DatePickerComponent];

@NgModule({
	imports: [CommonModule, FormsModule, IconModule, InputDateModule],
	declarations: [...components],
	exports: [...components],
})
export class DatePickerModule {}
