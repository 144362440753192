import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-input-group',
	host: {
		class: 'input-group',
	},
	templateUrl: './input-group.component.html',
	styleUrls: ['./input-group.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class InputGroupComponent {
	@Input() legend!: string;

	constructor() {}
}
