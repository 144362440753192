import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Table} from 'primeng/table';

@Component({
	selector: 'app-table-search',
	templateUrl: './table-search.component.html',
	styleUrls: ['./table-search.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TableSearchComponent {
	@Input() table!: Table;
	@Input() matchMode = 'contains';

	constructor() {}
}
