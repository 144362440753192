import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IconComponent} from './icon.component';

const components = [IconComponent];

@NgModule({
	imports: [CommonModule],
	declarations: [...components],
	exports: [...components],
})
export class IconModule {}
