import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {InputSelectModule} from 'src/app/shared/components/input-select/input-select.module';

import {TabsComponent} from './tabs.component';
import {TabPaneComponent} from './components/tab-pane.component';
import {TabControlsDirective} from './directives/tab-controls.directive';
import {TabContentDirective} from './directives/tab-content.directive';

const components = [TabsComponent, TabPaneComponent, TabControlsDirective, TabContentDirective];

@NgModule({
	imports: [CommonModule, PortalModule, InputSelectModule],
	declarations: [...components],
	exports: [...components],
})
export class TabsModule {}
