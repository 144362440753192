import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {Router, RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError} from '@angular/router';
import {Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import vhCheck from 'vh-check';
// import rg4js from 'raygun4js';
import {SessionService, LocaleService, raygunSetUser} from './core';
import {environment} from 'src/environments/environment';

// rg4js('apiKey', 'yiSe416GiJUzWrilPqCQaA');
// rg4js('setVersion', '1.0.0');
// rg4js('enablePulse', true);
// rg4js('enableCrashReporting', true);
// rg4js('logContentsOfXhrCalls', true);

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
	public title = 'te-pari-app';
	public init = false;
	public loading = true;
	public loadingTimeout: any;
	public language!: string;
	public preferredLocale!: string | null;
	public viewHeight: any;
	public unsubscribe$: Subject<boolean> = new Subject();

	constructor(
		private router: Router,
		private renderer: Renderer2,
		private localeService: LocaleService,
		private session: SessionService,
	) {}

	public ngOnInit() {
		this.localeService.detectUserLocale();

		this.detectViewHeight();
		this.detectScrollbarWidth();

		this.router.events
			.pipe(
				takeUntil(this.unsubscribe$),
				filter((e: any) => e instanceof NavigationStart || e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError), // prettier-ignore
			)
			.subscribe((e: RouterEvent) => {
				this.loadingInterceptor(e);
				this.raygunInterceptor(e);
			});

		this.renderer.addClass(document.body, 'app-loaded');
		this.init = true;

		if (this.session.isLoggedIn() && this.session.username) {
			raygunSetUser(this.session.username);
		}
	}

	public ngOnDestroy() {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	public isNavActive() {
		const forbiddenRoutes = ['/', '/login', '/reset-password'];
		return !forbiddenRoutes.includes(this.router.url);
	}

	private loadingInterceptor(e: RouterEvent) {
		switch (true) {
			case e instanceof NavigationStart: {
				this.loadingTimeout = window.setTimeout(() => {
					this.loading = true;
				}, 500);
				break;
			}

			case e instanceof NavigationEnd:
			case e instanceof NavigationCancel:
			case e instanceof NavigationError: {
				window.clearTimeout(this.loadingTimeout);
				this.loading = false;
				break;
			}

			default: {
				break;
			}
		}
	}

	private raygunInterceptor(e: RouterEvent) {
		if (!environment.production) return;

		// Track page views when the NavigationEnd event occurs
		// if (e instanceof NavigationEnd) {
		// 	rg4js('trackEvent', {
		// 		type: 'pageView',
		// 		path: e.url,
		// 	});
		// }

		// Track navigation errors when the NavigationError event occurs
		// if (e instanceof NavigationError) {
		// 	rg4js('send', {
		// 		error: e.error,
		// 	});
		// }
	}

	private detectViewHeight() {
		this.viewHeight = vhCheck({
			force: true,
			updateOnTouch: true,
		});
	}

	private detectScrollbarWidth() {
		document.documentElement.insertAdjacentHTML(
			'beforeend',
			`
				<div id="box-outer" style="position: absolute; top: -200px; left: -200px; width: 50px; height: 50px; overflow: auto;">
					<div id="box-inner" style="height: 100px;">
				</div>
			`,
		);

		const outer: HTMLElement | null = document.querySelector('#box-outer');
		const inner: HTMLElement | null = document.querySelector('#box-inner');

		if (outer && inner) {
			const scrollbarWidth = `${outer.offsetWidth - inner.offsetWidth}px`;

			document.documentElement.style.setProperty('--scrollbar-width', scrollbarWidth);

			outer.remove();
			inner.remove();
		}
	}
}
