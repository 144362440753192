import {AfterContentInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {uniqueId} from 'lodash-es';

@Directive({
	selector: '[input-error]',
	host: {
		'class': 'input__error',
		'[class.-hidden]': `isHidden`,
		'[attr.aria-hidden]': `isHidden`,
	},
})
export class InputErrorDirective implements AfterContentInit {
	public el!: HTMLElement;
	public id!: string;
	public isHidden = true;

	@Input() match!: string;

	constructor(private renderer: Renderer2, private elRef: ElementRef) {
		this.el = this.elRef.nativeElement;
	}

	ngAfterContentInit(): void {
		this.id = uniqueId(`input-error-msg-`);
		this.renderer.setAttribute(this.el, 'id', this.id);
	}

	public hide(): void {
		this.isHidden = true;
	}

	public show(): void {
		this.isHidden = false;
	}
}
