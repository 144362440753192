import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from '../button/button.module';
import {ButtonGroupComponent} from './button-group.component';

const components = [ButtonGroupComponent];

@NgModule({
	imports: [CommonModule, ButtonModule],
	declarations: [...components],
	exports: [...components],
})
export class ButtonGroupModule {}
