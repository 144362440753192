import {Directive, Input} from '@angular/core';
import {Validator, NG_VALIDATORS, FormControl} from '@angular/forms';
import {toString} from 'lodash-es';

@Directive({
	selector: '[unique]',
	providers: [{provide: NG_VALIDATORS, useExisting: UniqueDirective, multi: true}],
})
export class UniqueDirective implements Validator {
	@Input() unique: string[] | undefined;

	constructor() {}

	public validate(control: FormControl): any {
		if (!control.root || !this.unique) return;

		const val = toString(control.value).toLowerCase();

		if (this.unique.includes(val)) {
			const err = {
				unique: true,
			};

			control.root.setErrors(err);

			return err;
		}
	}
}
