import {Component, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Subject} from 'rxjs';
import {DrawerComponent} from 'src/app/shared/components/drawer/drawer.component';
import {ModalComponent} from 'src/app/shared/components/modal/modal.component';
import {NotificationsService, UtilService} from 'src/app/core';

@Component({
	template: ``,
})
export class FormComponent implements OnDestroy {
	@ViewChild(NgForm) form!: NgForm;

	@Input() parent?: ModalComponent | DrawerComponent;
	@Input() closeParent = false;
	@Input() redirect = false;

	public unsubscribe$ = new Subject<boolean>();
	public loading = false;

	constructor(public notifications: NotificationsService, public util: UtilService) {}

	public ngOnDestroy() {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	public async submit(form: NgForm) {
		if (form.invalid || this.loading) return;

		try {
			this.loading = true;
			this.parent?.disable();

			const res = await this.handleSubmit(form);
			this.util.log(res);

			this.handleReset(form);
			this.loading = false;
			this.parent?.enable();

			if (this.closeParent) {
				this.parent?.close();
			}

			if (this.redirect) {
				this.handleRedirect(res);
			}
		} catch (err: any) {
			this.loading = false;
			this.parent?.enable();

			this.notifications.create({
				type: 'danger',
				message: err,
			});
		}
	}

	public handleSubmit(form: NgForm): Promise<any> | any {}

	public handleReset(form: NgForm) {
		form.resetForm(form.value);
	}

	public handleRedirect(data: any) {}
}
