import {
	AfterContentInit,
	Component,
	ContentChildren,
	Input,
	OnDestroy,
	QueryList,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TabPaneComponent} from './components/tab-pane.component';

@Component({
	selector: 'app-tabs',
	host: {
		class: 'tabs',
	},
	templateUrl: 'tabs.component.html',
	styleUrls: ['./tabs.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TabsComponent implements AfterContentInit, OnDestroy {
	@ContentChildren(TabPaneComponent, {descendants: false})
	tabs!: QueryList<TabPaneComponent>;

	public unsubscribe$: Subject<boolean> = new Subject();

	constructor() {}

	public ngAfterContentInit(): void {
		this.checkActiveTab();
		this.tabs.changes.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.checkActiveTab());
	}

	public ngOnDestroy(): void {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	private checkActiveTab(): void {
		const activeTab = this.tabs.find((t) => t.active);

		if (activeTab) return;

		const tabToOpen = this.tabs.toArray().find((t) => !t.disabled);

		if (!tabToOpen) return;

		tabToOpen.open();
	}

	public selectTabById(id: string): void {
		const tab = this.tabs.find((t) => t.id === id);

		if (tab) {
			this.selectTab(tab);
		}
	}

	public selectTab(tab: TabPaneComponent): void {
		if (tab.active) return;

		this.tabs.forEach((t) => {
			if (t !== tab) {
				t.close();
			} else {
				t.open();
			}
		});
	}
}
