import {Directive, ElementRef, Input} from '@angular/core';

@Directive({
	selector: 'app-card-content, [card-content]',
	host: {
		'class': 'card__content',
		'[class.-grow]': 'grow',
	},
})
export class CardContentDirective {
	@Input() grow = false;

	public el: HTMLElement;

	constructor(public elRef: ElementRef) {
		this.el = this.elRef.nativeElement;
	}
}
