import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import {Table} from 'primeng/table';
import {Subject, takeUntil} from 'rxjs';
import {TableColumn} from 'src/app/models';
import {TableService} from './services/table.service';
import {TableExportFormats} from './types';

@Component({
	selector: 'app-table',
	host: {
		'class': 'table',
		'[style.height]': 'tableHeight',
	},
	templateUrl: './table.component.html',
	styleUrls: ['./table.component.scss'],
	providers: [TableService],
	encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
	@ViewChild(Table, {static: true})
	get table() {
		return this.tableService.table;
	}
	set table(table: Table) {
		this.tableService.table = table;
	}

	@Input()
	get uid() {
		return this.tableService.uid;
	}
	set uid(uid: string) {
		this.tableService.uid = uid;
	}

	@Input()
	get columns() {
		return this.tableService.columns;
	}
	set columns(columns: TableColumn[]) {
		this.tableService.columns = columns;
	}

	@Input()
	get rows() {
		return this.tableService.rows;
	}
	set rows(rows: any[]) {
		this.tableService.rows = rows;
	}

	@Input()
	get selectedRows() {
		return this.tableService.selectedRows;
	}
	set selectedRows(selectedRows: any[]) {
		this.tableService.selectedRows = selectedRows;
	}

	@Input()
	get searchFields() {
		return this.tableService.searchFields;
	}
	set searchFields(searchFields: string[]) {
		this.tableService.searchFields = searchFields;
	}

	@Input()
	get enableControls() {
		return this.tableService.enableControls;
	}
	set enableControls(enableControls: boolean) {
		this.tableService.enableControls = enableControls;
	}

	@Input()
	get enableSearch() {
		return this.tableService.enableSearch;
	}
	set enableSearch(enableSearch: boolean) {
		this.tableService.enableSearch = enableSearch;
	}

	@Input()
	get enableSort() {
		return this.tableService.enableSort;
	}
	set enableSort(enableSort: boolean) {
		this.tableService.enableSort = enableSort;
	}

	@Input()
	get enableFilters() {
		return this.tableService.enableFilters;
	}
	set enableFilters(enableFilters: boolean) {
		this.tableService.enableFilters = enableFilters;
	}

	@Input()
	get enableExports() {
		return this.tableService.enableExports;
	}
	set enableExports(enableExports: boolean) {
		this.tableService.enableExports = enableExports;
	}

	@Input()
	get enableLayouts() {
		return this.tableService.enableLayouts;
	}
	set enableLayouts(enableLayouts: boolean) {
		this.tableService.enableLayouts = enableLayouts;
	}

	@Input()
	get enableScroll() {
		return this.tableService.enableScroll;
	}
	set enableScroll(enableScroll: boolean) {
		this.tableService.enableScroll = enableScroll;
	}

	@Input()
	get enableColumnStats() {
		return this.tableService.enableColumnStats;
	}
	set enableColumnStats(enableColumnStats: boolean) {
		this.tableService.enableColumnStats = enableColumnStats;
	}

	@Input()
	get enableRowSelect() {
		return this.tableService.enableRowSelect;
	}
	set enableRowSelect(enableRowSelect: boolean) {
		this.tableService.enableRowSelect = enableRowSelect;
	}

	@Input()
	get clickable() {
		return this.tableService.clickable;
	}
	set clickable(clickable: boolean) {
		this.tableService.clickable = clickable;
	}

	@Input()
	tableHeight: string = '100%';

	@Input()
	get exportFilename() {
		return this.tableService.exportFilename;
	}
	set exportFilename(exportFilename: string) {
		this.tableService.exportFilename = exportFilename;
	}

	@Input()
	get exportFormats() {
		return this.tableService.exportFormats;
	}
	set exportFormats(formats: TableExportFormats) {
		this.tableService.exportFormats = {
			...this.exportFormats,
			...formats,
		};
	}

	@Output()
	selectedRowsChange: EventEmitter<any[]> = new EventEmitter<any[]>();

	@Output()
	rowClicked: EventEmitter<any> = new EventEmitter<any>();

	get tableSummary() {
		const rowsTotal = this.table.rows || 0;
		const rowsFiltered = this.table.filteredValue?.length || 0;
		const rowsSelected = this.selectedRows?.length || 0;

		if (rowsSelected > 0) {
			if (this.table.filteredValue) {
				//prettier-ignore
				return `selected ${rowsSelected} ${rowsSelected !== 1 ? 'entries' : 'entry'}, showing ${rowsFiltered} of ${rowsTotal} ${rowsTotal !== 1 ? 'entries' : 'entry'}`;
			} else {
				return `selected ${rowsSelected} of ${rowsTotal} ${rowsTotal !== 1 ? 'entries' : 'entry'}`;
			}
		}

		if (this.table.filteredValue) {
			return `showing ${rowsFiltered} of ${rowsTotal} ${rowsTotal !== 1 ? 'entries' : 'entry'}`;
		}

		return `showing ${rowsTotal} ${rowsTotal !== 1 ? 'entries' : 'entry'}`;
	}

	get tableWidth() {
		const toggleWidth = this.enableColumnStats || this.enableRowSelect ? 50 : 0;
		const columnsWidth = this.columns.length * 200;

		return `${columnsWidth + toggleWidth}px`;
	}

	public unsubscribe$: Subject<boolean> = new Subject();

	constructor(public tableService: TableService) {}

	// Lifecycle
	// ----------------------------------------

	public ngOnInit() {
		this.tableService.init();

		// prettier-ignore
		this.table.onFilter
			.pipe(takeUntil(this.unsubscribe$))
			.subscribe((e) => this.tableService.calcColumnStats());
	}

	public ngOnChanges(changes: SimpleChanges) {
		if (changes.columns) {
			this.tableService.calcColumnWidth();
			this.tableService.calcColumnStats();
			return;
		}

		if (changes.rows) {
			this.tableService.calcColumnStats();
			return;
		}
	}

	public ngOnDestroy() {
		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	// Rows
	// ----------------------------------------

	public onRowClick($event: any, row: any) {
		this.rowClicked.emit(row);
	}

	public onSelectedChange($event: any) {
		this.selectedRowsChange.emit(this.selectedRows);
	}

	// Export
	// ----------------------------------------

	public exportCSV() {
		this.tableService.exportCSV();
	}

	public async exportPdf() {
		this.tableService.exportPdf();
	}
}
