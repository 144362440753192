import {Injectable} from '@angular/core';
import {cloneDeep, get} from 'lodash-es';
import {Table} from 'primeng/table';
import {
	DATE_MATCH_MODES,
	NUMBER_MATCH_MODES,
	SELECT_MATCH_MODES,
	StorageData,
	StorageService,
	TEXT_MATCH_MODES,
} from 'src/app/core';
import {TableColumn, TableData, TableLayout, TableLayoutColumn, TableColumnStats} from 'src/app/models';
import {TableExportFormats} from '../types';

@Injectable()
export class TableService {
	public table!: Table;
	public uid!: string;
	public columns!: TableColumn[];
	public columnStats!: TableColumnStats;
	public rows!: any[];
	public selectedRows: any[] = [];
	public selectedStat: 'avg' | 'min' | 'max' = 'avg';
	public selectedLayout?: TableLayout | null;
	public searchFields: string[] = [];
	public enableControls = true;
	public enableSearch = false;
	public enableSort = true;
	public enableFilters = true;
	public enableExports = false;
	public enableLayouts = false;
	public enableScroll = true;
	public enableColumnStats = false;
	public enableRowSelect = false;
	public clickable = false;
	public columnWidth = '200px';
	public exportFilename = 'export';
	public exportFormats: TableExportFormats = {
		csv: true,
		pdf: true,
	};

	private _tableData!: StorageData<TableData>;
	private _columns!: string[];

	get tableData() {
		return this._tableData.value;
	}
	set tableData(value: TableData) {
		this._tableData.value = value;
		this._tableData.set();
	}

	constructor(public storage: StorageService) {}

	// Init
	// ----------------------------------------

	public init() {
		this.initTableData();
		this.initColumns();
		// this.initLayout();
	}

	private initTableData() {
		const tableData = this.storage.getItem<TableData>(this.uid);

		if (!tableData || !tableData.value) {
			this._tableData = this.storage.setItem<TableData>(this.uid, {layouts: []});
			return;
		}

		this._tableData = tableData;
		this.validateLayouts();
	}

	private initColumns() {
		this._columns = this.columns.map((c) => {
			return c.field;
		});

		this._columns;

		this.calcColumnWidth();
	}

	// Table layouts
	// ----------------------------------------

	public createLayout() {
		this.resetTable();
		this.selectedLayout = this.getNewLayout();
	}

	public deleteLayout(layout: TableLayout) {
		const data = this.tableData;
		data.layouts = data?.layouts.filter((l) => l.id !== layout.id);

		this.tableData = data;
		this.selectedLayout = null;
	}

	public applyLayout(layout: TableLayout) {
		if (!layout || !this.table) return;

		this.resetTable();

		// Set table columns
		const columns = this.columns
			.map((column) => {
				const layoutColumn = layout.columns.find((c) => c.field == column.field);

				if (column.enableLayoutToggle !== false && layoutColumn) {
					column.hidden = !layoutColumn.enabled;
				}

				return column;
			})
			.sort((column1, column2) => {
				if (column1.enableLayoutSort === false && column2.enableLayoutSort !== false) return -1;
				if (column1.enableLayoutSort !== false && column2.enableLayoutSort === false) return 1;

				const index1 = layout.columns.findIndex((c) => c.field === column1.field);
				const index2 = layout.columns.findIndex((c) => c.field === column2.field);
				return index1 - index2;
			});

		this.columns = [...columns];
		this.calcColumnWidth();

		// Set table filters
		layout.columns.forEach((column) => {
			if (!column.enabled) return;

			const filters = column.filters.filter((f) => f.value);

			if (!filters.length) return;

			this.table.filters[column.field] = cloneDeep(filters);
		});

		// Set table sort
		if (layout.sortField) {
			this.table.sortField = layout.sortField;
		}

		if (layout.sortOrder) {
			this.table.sortOrder = layout.sortOrder;
		}

		// Force table updates
		this.table._filter();
		this.table.sortSingle();
	}

	private validateLayouts() {
		const data = this.tableData;

		if (!data.layouts || !Array.isArray(data.layouts)) {
			data.layouts = [];
		} else {
			data.layouts = data.layouts.map((l) => this.validateLayout(l));
		}

		this.tableData = data;
	}

	private validateLayout(layout: TableLayout) {
		const columns = this.columns.filter((c) => c.enableLayoutToggle !== false);
		const columnFields = columns.map((c) => c.field);

		// Remove any columns that have been removed from the table
		layout.columns = layout.columns.filter((col) => {
			return columnFields.includes(col.field);
		});

		// Add any new columns that have been added to the table
		// Update any filters that no longer match the column filter settings
		columns.forEach((col) => {
			const layoutColumn = layout.columns.find((c) => c.field === col.field);

			if (!layoutColumn) {
				layout.columns.push({
					enabled: false,
					enableSort: true,
					field: col.field,
					filters: [],
				});
				return;
			}

			let matchModes: string[];

			switch (col.filterType) {
				case 'select':
					matchModes = SELECT_MATCH_MODES.map((m) => m.code);
					break;

				case 'date':
					matchModes = DATE_MATCH_MODES.map((m) => m.code);
					break;

				case 'number':
					matchModes = NUMBER_MATCH_MODES.map((m) => m.code);
					break;

				default:
					matchModes = TEXT_MATCH_MODES.map((m) => m.code);
					break;
			}

			layoutColumn.filters = layoutColumn.filters.filter((f) => {
				return f.matchMode ? matchModes.includes(f.matchMode) : false;
			});

			layoutColumn.enableSort = col.enableLayoutSort === false ? false : true;
		});

		return layout;
	}

	private getNewLayout(): TableLayout {
		const columns: TableLayoutColumn[] = this.columns
			.filter((c) => {
				return c.enableLayoutToggle !== false;
			})
			.map((c) => {
				return {
					enabled: true,
					enableSort: c.enableLayoutSort === false ? false : true,
					field: c.field,
					filters: [],
				};
			});

		return {
			id: '',
			name: '',
			description: '',
			columns,
		};
	}

	// Table reset
	// ----------------------------------------

	public resetTable() {
		this.table.reset();
		this.resetTableColumns();
		this.resetTableFilters();
	}

	private resetTableColumns() {
		const columns = this.columns
			.map((c) => {
				if (c.enableLayoutToggle !== false) {
					c.hidden = this._columns.indexOf(c.field) === -1;
				}
				return c;
			})
			.sort((c1, c2) => {
				if (c1.enableLayoutSort === false && c2.enableLayoutSort !== false) return -1;
				if (c1.enableLayoutSort !== false && c2.enableLayoutSort === false) return 1;

				const index1 = this._columns.indexOf(c1.field);
				const index2 = this._columns.indexOf(c2.field);
				return index1 - index2;
			});

		this.columns = [...columns];
		this.calcColumnWidth();
	}

	private resetTableFilters() {
		this.table.filters = {};
		this.table._filter();
	}

	// Table utilities
	// ----------------------------------------

	public calcColumnWidth() {
		const offset = this.selectedRows ? 50 : 0;
		const columnCount = this.columns.filter((c) => !c.hidden).length;
		const columnWidth = 200;
		const tableWidth = this.table.el.nativeElement.offsetWidth - offset || 0;

		if (tableWidth / columnCount <= columnWidth) {
			this.columnWidth = `${columnWidth}px`;
		} else {
			this.columnWidth = `calc(${100 / columnCount}% - ${offset}px)`;
		}
	}

	public calcColumnStats() {
		this.columnStats = {};

		if (!this.enableColumnStats) return;

		this.columns
			.filter((c) => {
				return c.enableStats && c.fieldType === 'number';
			})
			.forEach((c) => {
				let stat;

				const data = this.table?.filteredValue || this.rows;

				const values = data
					.map((r) => {
						const value = get(r, c.field);
						return parseInt(value);
					})
					.filter((v) => {
						return !isNaN(v);
					});

				if (!values.length) return;

				switch (this.selectedStat) {
					case 'avg':
						stat = values.reduce((a, b) => a + b) / values.length;
						break;

					case 'min':
						stat = Math.min(...values);
						break;

					case 'max':
						stat = Math.max(...values);
						break;
				}

				this.columnStats[c.field] = stat;
			});
	}

	public updateSelectedStat(stat: 'avg' | 'min' | 'max') {
		this.selectedStat = stat;
		this.calcColumnStats();
	}

	public getRowIndex(row: any) {
		return this.rows.indexOf(row);
	}

	// Table export
	// ----------------------------------------

	public exportCSV() {
		const columns = this.columns.filter((c) => !c.hidden);
		const header = columns.map((c) => `"${c.header}"`);
		const csv = [header.join(',')];
		let rows;

		// Map row data
		if (this.table?.filteredValue?.length) {
			rows = [...this.table.filteredValue];
		} else {
			rows = [...this.rows];
		}

		rows.forEach((row) => {
			const values: string[] = [];
			columns.forEach((c) => {
				const value = get(row, c.field);
				value ? values.push(`"${value}"`) : values.push('""');
			});
			csv.push(values.join(','));
		});

		// Download CSV file
		const a = document.createElement('a');
		a.setAttribute('id', 'download-link');
		a.setAttribute('download', `${this.exportFilename}.csv`);
		a.setAttribute(
			'href',
			URL.createObjectURL(
				new Blob([csv.join('\r\n')], {
					type: 'text/csv;encoding:utf-8',
				}),
			),
		);
		document.body.appendChild(a);
		a.click();
		document.querySelector('#download-link')?.remove();
	}

	public async exportPdf() {
		const [pdf, autoTable] = await Promise.all([import('jspdf'), import('jspdf-autotable')]);
		const doc = new pdf.jsPDF({orientation: 'landscape'});

		// Map column data
		const columns = this.columns
			.filter((c) => {
				return !c.hidden;
			})
			.map((c) => ({
				title: c.header,
				dataKey: c.field,
			}));

		// Map row data
		let rows;

		if (this.table?.filteredValue?.length) {
			rows = [...this.table.filteredValue];
		} else {
			rows = [...this.rows];
		}

		const body: any[][] = rows.map((row) => {
			const data: any[] = [];
			columns.forEach((c) => {
				const value = get(row, c.dataKey);
				value ? data.push(value) : data.push('');
			});
			return data;
		});

		// Configure and save the pdf
		autoTable.default(doc, {
			columns,
			body,
			headStyles: {
				overflow: 'visible',
				fillColor: '#0d7cba',
			},
		});

		doc.save(`${this.exportFilename}.pdf`);
	}
}
