import {TemplatePortal} from '@angular/cdk/portal';
import {ViewContainerRef} from '@angular/core';
import {
	OnInit,
	Component,
	EventEmitter,
	Input,
	Output,
	ElementRef,
	Renderer2,
	ChangeDetectorRef,
	ViewChild,
	ContentChild,
	TemplateRef,
} from '@angular/core';
import {uniqueId} from 'lodash-es';
import {TabContentDirective} from '../directives/tab-content.directive';

@Component({
	selector: 'app-tab-pane, [tab-pane]',
	host: {
		role: 'tabpanel',
		class: 'tabs__pane',
	},
	template: `
		<ng-container [cdkPortalHost]="content"></ng-container>
		<ng-template #template>
			<ng-content></ng-content>
		</ng-template>
	`,
})
export class TabPaneComponent implements OnInit {
	@ContentChild(TabContentDirective, {read: TemplateRef, static: true})
	explicitContent!: TemplateRef<any>;
	@ViewChild('template', {static: true}) implicitContent!: TemplateRef<any>;

	@Input() id!: string;
	@Input() label!: string | number;
	@Input() active = false;
	@Input() disabled = false;
	@Input() hidden = false;

	@Output() tabOpen: EventEmitter<undefined> = new EventEmitter<undefined>();
	@Output() tabClose: EventEmitter<undefined> = new EventEmitter<undefined>();

	private el!: HTMLElement;
	private contentPortal: TemplatePortal | null = null;

	public get content(): TemplatePortal | null {
		return this.active ? this.contentPortal : null;
	}

	constructor(
		private renderer: Renderer2,
		private viewContainerRef: ViewContainerRef,
		private elRef: ElementRef,
		private cdRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.el = this.elRef.nativeElement;
		this.contentPortal = new TemplatePortal(this.explicitContent || this.implicitContent, this.viewContainerRef);

		if (!this.id) {
			this.id = uniqueId('pane-');
			this.renderer.setAttribute(this.el, 'id', this.id);
		}

		this.renderer.setAttribute(this.el, 'aria-labelledby', `${this.id}-tab`);
	}

	open(): void {
		if (this.disabled) return;

		this.tabOpen.emit();
		this.active = true;
		this.cdRef.detectChanges();
	}

	close(): void {
		if (this.disabled) return;

		this.tabClose.emit();
		this.active = false;
		this.cdRef.detectChanges();
	}
}
