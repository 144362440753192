<ng-content select="app-card-actions, [card-actions]"></ng-content>

<ng-container [ngSwitch]="type">
	<ng-container *ngSwitchCase="'link'">
		<a class="card__inner" [routerLink]="link" [attr.aria-label]="label">
			<ng-container *ngTemplateOutlet="tempContent"> </ng-container>
		</a>
	</ng-container>

	<ng-container *ngSwitchCase="'button'">
		<a class="card__inner" role="button" [attr.aria-label]="label" (click)="cardClick.emit()">
			<ng-container *ngTemplateOutlet="tempContent"> </ng-container>
		</a>
	</ng-container>

	<ng-container *ngSwitchDefault>
		<ng-container *ngTemplateOutlet="tempContent"> </ng-container>
	</ng-container>
</ng-container>

<ng-template #tempContent>
	<ng-content select="app-card-header, [card-header]"></ng-content>
	<ng-content></ng-content>
	<ng-content select="app-card-footer, [card-footer]"></ng-content>
</ng-template>
