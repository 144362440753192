import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DevGuard, LoggedInGuard, LoggedOutGuard} from './core';

// Pre-loaded Page Modules
import {LoginPageModule} from './pages/login/login.module';
import {ResetPasswordPageModule} from './pages/reset-password/reset-password.module';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'login',
	},
	{
		path: 'animals',
		loadChildren: () => import(`./pages/animals/animals.module`).then((m) => m.AnimalsPageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'measurements',
		loadChildren: () => import(`./pages/measurements/measurements.module`).then((m) => m.MeasurementsPageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'treatments',
		loadChildren: () => import(`./pages/treatments/treatments.module`).then((m) => m.TreatmentsPageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'alerts',
		loadChildren: () => import(`./pages/alerts/alerts.module`).then((m) => m.AlertsPageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'sales-and-purchases',
		loadChildren: () =>
			import(`./pages/sales-and-purchases/sales-and-purchases.module`).then((m) => m.SalesAndPurchasesPageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'help-and-support',
		loadChildren: () =>
			import(`./pages/help-and-support/help-and-support.module`).then((m) => m.HelpAndSupportPageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'import',
		loadChildren: () => import(`./pages/import-animals/import-animals.module`).then((m) => m.ImportAnimalsModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'profile',
		loadChildren: () => import(`./pages/profile/profile.module`).then((m) => m.ProfilePageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'search',
		loadChildren: () => import(`./pages/search/search.module`).then((m) => m.SearchPageModule),
		canActivate: [LoggedInGuard],
	},
	{
		path: 'login',
		loadChildren: () => LoginPageModule,
		canActivate: [LoggedOutGuard],
	},
	{
		path: 'reset-password',
		loadChildren: () => ResetPasswordPageModule,
		canActivate: [LoggedOutGuard],
	},
	{
		path: 'error',
		loadChildren: () => import(`./pages/error/error.module`).then((m) => m.ErrorPageModule),
	},
	{
		path: 'dev',
		loadChildren: () => import(`./pages/_dev/dev.module`).then((m) => m.DevPageModule),
		canActivate: [DevGuard],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
