import {Injectable} from '@angular/core';
import {Notification} from 'src/app/models';

@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	public notifications: Notification[] = [];

	constructor() {}

	public create(notification: Notification) {
		if (!notification.timer) {
			notification.timer = 5000;
		}

		this.notifications.push(notification);
	}

	public destroy(index: number) {
		this.notifications.splice(index, 1);
	}

	public destroyAll() {
		this.notifications.length = 0;
	}
}
