import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {ComponentsModule} from 'src/app/shared/components/components.module';
import {DirectivesModule} from 'src/app/shared/directives/directives.module';

// Components
import {ContentComponent} from './content/content.component';
import {HeaderComponent} from './header/header.component';
import {IconDefinitionsComponent} from './icon-definitions/icon-definitions.component';
import {NavComponent} from './nav/nav.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {RouterComponent} from './router/router.component';
import {SearchBoxComponent} from './search-box/search-box.component';

const components = [
	ContentComponent,
	HeaderComponent,
	IconDefinitionsComponent,
	NavComponent,
	NotificationsComponent,
	RouterComponent,
	SearchBoxComponent,
];

@NgModule({
	imports: [CommonModule, RouterModule, FormsModule, ComponentsModule, DirectivesModule],
	exports: [...components],
	declarations: [...components],
})
export class LayoutModule {}
