import {Component, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-sortable-list-handle',
	host: {
		class: 'sortable-list-handle',
	},
	templateUrl: './sortable-list-handle.component.html',
	styleUrls: ['./sortable-list-handle.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SortableListHandleComponent {
	constructor() {}
}
