import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
	enableProdMode();

	const locale = localStorage.getItem('locale');
	const currentLocale = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
	const params = new URLSearchParams(window.location.search);

	if (locale && locale !== currentLocale) {
		window.location.href = `https://t30.tepari.com/${locale}/?${params.toString()}}`;
	} else {
		execute();
	}
} else {
	execute();
}

function execute() {
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.catch((err) => console.error(err));
}
