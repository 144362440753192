import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'src/app/shared/components/button/button.module';
import {IconModule} from 'src/app/shared/components/icon/icon.module';

import {ModalComponent} from './modal.component';
import {ModalToggleDirective} from './directives/modal-toggle.directive';

const components = [ModalComponent, ModalToggleDirective];

@NgModule({
	imports: [CommonModule, ButtonModule, IconModule],
	declarations: [...components],
	exports: [...components],
})
export class ModalModule {}
