import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {InputSelectModule} from 'src/app/shared/components/input-select/input-select.module';

import {StatusIndicatorComponent} from './status-indicator.component';

const components = [StatusIndicatorComponent];

@NgModule({
	imports: [CommonModule, PortalModule, InputSelectModule],
	declarations: [...components],
	exports: [...components],
})
export class StatusIndicatorModule {}
