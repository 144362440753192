<div class="panel__header">
	<button
		[id]="labelId"
		type="button"
		class="panel__toggle"
		role="tab"
		[attr.aria-expanded]="active"
		[attr.aria-controls]="panelId"
		[attr.tabindex]="disabled ? -1 : 0"
		[disabled]="disabled"
		(click)="toggle()"
	>
		<app-icon class="panel__indicator" name="arrow-small" [rotate]="rotate"></app-icon>
		<ng-content select="app-panel-title, [panel-title]"></ng-content>
	</button>

	<ng-content select="app-panel-actions, [panel-actions]"></ng-content>
</div>

<div [id]="panelId" [attr.aria-labelledby]="labelId" role="tabpanel">
	<div *ngIf="active" [@panelAnimation]="active ? 'open' : 'closed'" class="panel__body">
		<ng-content select="app-panel-content, [panel-content], app-panel-table, [panel-table]"></ng-content>
	</div>
</div>
