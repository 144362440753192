import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProgressSpinnerModule} from 'src/app/shared/components/progress-spinner/progress-spinner.module';

import {ButtonComponent} from './button.component';

const components = [ButtonComponent];

@NgModule({
	imports: [CommonModule, ProgressSpinnerModule],
	declarations: [...components],
	exports: [...components],
})
export class ButtonModule {}
