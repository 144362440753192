import {Component, Input, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-content',
	host: {
		class: 'page-content',
	},
	templateUrl: './content.component.html',
	styleUrls: ['./content.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ContentComponent {
	@Input() loading!: boolean;

	constructor() {}
}
