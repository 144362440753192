import {
	AfterContentInit,
	Component,
	ContentChild,
	ContentChildren,
	ElementRef,
	Input,
	OnChanges,
	Optional,
	QueryList,
	Renderer2,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import {NgForm, ValidationErrors} from '@angular/forms';
import {uniqueId} from 'lodash-es';

import {InputControlDirective} from './directives/input-control.directive';
import {InputErrorDirective} from './directives/input-error.directive';
import {InputLabelDirective} from './directives/input-label.directive';

@Component({
	selector: 'app-input',
	host: {
		'[class.input]': 'true',
		'[class.-submitted]': 'submitted',
		'[class.-untouched]': 'control.hasProp("untouched")',
		'[class.-touched]': 'control.hasProp("touched")',
		'[class.-pristine]': 'control.hasProp("pristine")',
		'[class.-dirty]': 'control.hasProp("dirty")',
		'[class.-valid]': 'control.hasProp("valid")',
		'[class.-invalid]': 'control.hasProp("invalid")',
		'[class.-pending]': 'control.hasProp("pending")',
		'[class.-focus]': 'control.isFocused',
	},
	templateUrl: './input.component.html',
	styleUrls: ['./input.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements AfterContentInit, OnChanges {
	@ContentChild(InputLabelDirective) label!: InputLabelDirective;
	@ContentChild(InputControlDirective) control!: InputControlDirective;
	@ContentChildren(InputErrorDirective)
	errors!: QueryList<InputErrorDirective>;

	@Input() icon!: string;
	@Input() loading = false;

	public id!: string;
	public el!: HTMLElement;
	public submitted = false;

	constructor(public elRef: ElementRef, public renderer: Renderer2, @Optional() public ngForm: NgForm) {}

	public ngAfterContentInit(): void {
		if (!this.label || !this.control) {
			console.error('The app-input component requires an input-label and an input-control.');
			return;
		}

		this.id = uniqueId(`input-`);
		this.el = this.elRef.nativeElement;
		this.renderer.setAttribute(this.label.el, 'for', this.id);
		this.renderer.setAttribute(this.control.el, 'id', this.id);

		this.ngForm?.ngSubmit.subscribe(() => {
			this.submitted = true;
			this.toggleErrors(this.control.getErrors());
		});

		this.control.ngControl?.statusChanges?.subscribe(() => {
			this.submitted = false;
			this.toggleErrors(this.control.getErrors());
		});

		this.control.inputChange.subscribe(() => {
			this.toggleErrors(this.control.getErrors());
		});

		if (this.control.el.tagName === 'SELECT' && !this.icon) {
			this.icon = 'arrow-small';
		}
	}

	public ngOnChanges(changes: SimpleChanges) {
		this.setDisabledState(changes.loading?.currentValue);
	}

	public setDisabledState(disabled: boolean) {
		if (!this.control) return;

		if (disabled) {
			this.renderer.setProperty(this.control.el, 'disabled', true);
		} else {
			this.renderer.setProperty(this.control.el, 'disabled', false);
		}
	}

	public toggleErrors(errors: ValidationErrors | null): void {
		const untouched = this.control.hasProp('pristine') && this.control.hasProp('untouched');

		if ((untouched && !this.submitted) || !errors) {
			this.errors.toArray().forEach((err) => err.hide());
			this.renderer.removeAttribute(this.control.el, 'aria-describedby');
			return;
		}

		const active = this.errors.toArray().find((err) => errors[err.match]);

		this.errors.toArray().forEach((err) => {
			if (err === active) {
				err.show();
				this.renderer.setAttribute(this.control.el, 'aria-describedby', err.id);
			} else {
				err.hide();
			}
		});
	}
}
