import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
import {InputRadioButtonsComponent} from './input-radio-buttons.component';
import {inputRadioButtonComponent} from './components/input-radio-button.component';

const components = [InputRadioButtonsComponent, inputRadioButtonComponent];

@NgModule({
	imports: [CommonModule, IconModule],
	declarations: [...components],
	exports: [...components],
})
export class InputRadioButtonsModule {}
