<!-- Table: Controls -->
<nav class="table-nav" *ngIf="enableControls">
	<!-- Nav Content -->
	<div class="table-nav__col -start">
		<ng-content select="[table-nav-content], passthrough-table-nav-content"></ng-content>

		<app-table-search *ngIf="enableSearch && searchFields"></app-table-search>

		<app-table-layout-picker *ngIf="enableLayouts && uid"></app-table-layout-picker>
	</div>

	<!-- Nav Controls -->
	<div class="table-nav__col -end">
		<div class="d-none d-contents:sm">
			<ng-content select="[table-nav-btn], passthrough-table-nav-btn"></ng-content>

			<ng-container *ngIf="enableExports">
				<button
					[dropdown]="exportMenu"
					btn
					type="button"
					theme="primary"
					size="small"
					shape="round"
					fill="outline"
					i18n-title
					title="Export data"
				>
					<ng-container i18n>Export As</ng-container>
				</button>

				<app-dropdown #exportMenu [closeOnClickInside]="true">
					<button *ngIf="exportFormats.csv" dropdown-item type="button" (click)="exportCSV()">
						<app-icon name="arrow"></app-icon>
						<span i18n>CSV (Excel)</span>
					</button>

					<button *ngIf="exportFormats.pdf" dropdown-item type="button" (click)="exportPdf()">
						<app-icon name="arrow"></app-icon>
						<span i18n>PDF</span>
					</button>
				</app-dropdown>
			</ng-container>
		</div>

		<div class="d-none:sm">
			<button
				[dropdown]="menu"
				btn-icon
				type="button"
				theme="primary"
				size="small"
				shape="round"
				fill="outline"
				i18n-title
				title="Table Controls"
			>
				<app-icon name="gear"></app-icon>
			</button>

			<app-dropdown #menu [closeOnClickInside]="true">
				<ng-content select="[table-nav-item], passthrough-table-nav-item"></ng-content>

				<button *ngIf="enableExports" dropdown-item type="button" (click)="exportCSV()">
					<app-icon name="arrow"></app-icon>
					<span i18n>Export as CSV</span>
				</button>

				<button *ngIf="enableExports" dropdown-item type="button" (click)="exportPdf()">
					<app-icon name="arrow"></app-icon>
					<span i18n>Export as PDF</span>
				</button>
			</app-dropdown>
		</div>
	</div>
</nav>

<!-- Table -->
<p-table
	#table
	[id]="uid"
	[class.clickable-rows]="clickable"
	[columns]="columns"
	[value]="rows"
	[rows]="rows.length"
	[(selection)]="selectedRows"
	[scrollable]="enableScroll"
	[virtualScroll]="true"
	scrollDirection="both"
	scrollHeight="flex"
	[exportFilename]="exportFilename"
	selectionMode="multiple"
	(onRowSelect)="onSelectedChange($event)"
	(onRowUnselect)="onSelectedChange($event)"
	(onHeaderCheckboxToggle)="onSelectedChange($event)"
>
	<ng-template pTemplate="header" let-columns>
		<tr>
			<th style="width: 50px" [hidden]="!enableColumnStats && !enableRowSelect">
				<p-tableHeaderCheckbox *ngIf="enableRowSelect"></p-tableHeaderCheckbox>
			</th>
			<th *ngFor="let col of columns" [hidden]="col.hidden" [style.width]="tableService.columnWidth">
				<div>
					<span>{{ col.header }}</span>

					<app-table-sort *ngIf="enableSort" [column]="col"></app-table-sort>

					<app-table-filter *ngIf="enableFilters" [column]="col"></app-table-filter>
				</div>
			</th>
		</tr>
	</ng-template>

	<ng-template pTemplate="body" let-row let-columns="columns" let-index="rowIndex">
		<tr [ngClass]="index % 2 === 0 ? 'even' : 'odd'">
			<td
				style="width: 50px"
				[hidden]="!enableColumnStats && !enableRowSelect"
				[pSelectableRow]="row"
				[pSelectableRowIndex]="index"
			>
				<p-tableCheckbox
					*ngIf="enableRowSelect"
					[value]="row"
					[index]="index"
					[disabled]="true"
				></p-tableCheckbox>
			</td>
			<td
				*ngFor="let col of columns"
				[hidden]="col.hidden"
				[style.width]="tableService.columnWidth"
				(click)="onRowClick($event, row)"
			>
				<ng-container [ngSwitch]="col.fieldType">
					<ng-container *ngSwitchCase="'currency'">
						<div class="text-right">
							{{ row | field: col.field | currency }}
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'date'">
						{{ row | field: col.field | date: 'shortDate' }}
					</ng-container>

					<ng-container *ngSwitchCase="'number'">
						<div class="text-right">
							{{ row | field: col.field | number: '1.0-2' }}
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'status'">
						<app-status-indicator [status]="row | field: col.field"></app-status-indicator>
					</ng-container>

					<ng-container *ngSwitchDefault>
						{{ row | field: col.field }}
					</ng-container>
				</ng-container>
			</td>
		</tr>
	</ng-template>

	<ng-template *ngIf="enableColumnStats" pTemplate="footer" let-columns>
		<tr>
			<td style="width: 50px">
				<button class="p-datatable-tfoot-btn" i18n-title title="Change Summary" [dropdown]="statsMenu">
					<app-icon name="gear"></app-icon>
				</button>

				<!-- Menu: Stats -->
				<app-dropdown
					#statsMenu
					placement="top-start"
					[offset]="[0, 12]"
					[closeOnClickInside]="true"
					[closeOnClickOutside]="true"
				>
					<button
						dropdown-item
						[active]="tableService.selectedStat === 'avg'"
						(click)="tableService.updateSelectedStat('avg')"
					>
						<app-icon name="arrow"></app-icon>
						<span i18n>Show Average</span>
					</button>

					<button
						dropdown-item
						[active]="tableService.selectedStat === 'min'"
						(click)="tableService.updateSelectedStat('min')"
					>
						<app-icon name="arrow"></app-icon>
						<span i18n>Show Min</span>
					</button>

					<button
						dropdown-item
						[active]="tableService.selectedStat === 'max'"
						(click)="tableService.updateSelectedStat('max')"
					>
						<app-icon name="arrow"></app-icon>
						<span i18n>Show Max</span>
					</button>
				</app-dropdown>
			</td>
			<td
				*ngFor="let col of columns"
				[hidden]="col.hidden"
				[style.width]="tableService.columnWidth"
				class="text-right"
			>
				<ng-container *ngIf="col.enableStats && col.fieldType === 'number'">
					{{ tableService.selectedStat }}:
					{{ tableService.columnStats[col.field] | number: '1.0-2' }}
				</ng-container>
			</td>
		</tr>
	</ng-template>

	<ng-template pTemplate="emptymessage" let-columns>
		<tr>
			<td [attr.colspan]="columns.length" [style.width]="tableWidth">No records found</td>
		</tr>
	</ng-template>
</p-table>

<!-- Table: Summary -->
<footer class="table-summary">
	<div class="table-summary__col -start">
		{{ tableSummary }}
	</div>

	<div class="table-summary__col -end">
		<ng-content select="[table-summary-content], passthrough-table-summary-content"></ng-content>
	</div>
</footer>
