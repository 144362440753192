import {Component, ViewEncapsulation} from '@angular/core';
import {animate, animation, style, transition, trigger} from '@angular/animations';
import {NotificationsService} from 'src/app/core';

// prettier-ignore
const notificationAnimation = trigger('notificationAnimation', [
	transition(':enter', animation([
		style({opacity: 0, transform: 'translateX(-100px)'}),
		animate('300ms ease-in-out', style({opacity: 1, transform: 'translateX(0%)'})),
	])),
	transition(':leave', animation([
		animate('300ms ease-in-out', style({opacity: 0, transform: 'translateX(-100px)'})),
		animate('300ms ease', style({height: 0})),
	]))
]);

@Component({
	selector: 'app-notifications',
	host: {
		class: 'notifications',
	},
	templateUrl: './notifications.component.html',
	styleUrls: ['./notifications.component.scss'],
	animations: [notificationAnimation],
	encapsulation: ViewEncapsulation.None,
})
export class NotificationsComponent {
	constructor(public notificationsService: NotificationsService) {}
}
