import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
	selector: 'app-date-picker',
	host: {
		class: 'date-picker',
	},
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent {
	@Input() label: string | undefined;
	@Input() date!: string;
	@Input() minDate = '';
	@Input() maxDate = '';
	@Input() enableDates!: string[];
	@Input() disableDates!: string[];

	@Output() dateChange: EventEmitter<string> = new EventEmitter();

	constructor() {}

	public onChange(): void {
		this.dateChange.emit(this.date);
	}
}
