import {Directive, Input, Optional} from '@angular/core';
import {RouterLink, RouterLinkWithHref} from '@angular/router';

@Directive({
	selector: '[routerLinkDisabled]',
})
export class RouterLinkDisabledDirective {
	@Input() routerLinkDisabled!: boolean;

	constructor(@Optional() routerLink: RouterLink, @Optional() routerLinkWithHref: RouterLinkWithHref) {
		const link = routerLink || routerLinkWithHref;

		// Save original method
		const onClick = link.onClick;

		// Replace method
		link.onClick = (...args) => {
			if (this.routerLinkDisabled) {
				return routerLinkWithHref ? false : true;
			} else {
				return onClick.apply(link, args);
			}
		};
	}
}
