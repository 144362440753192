<div class="input__group">
	<ng-content select="[input-control]"></ng-content>

	<ng-content select="[input-label]"></ng-content>

	<app-icon *ngIf="icon && !loading" [name]="icon"></app-icon>

	<app-progress-spinner *ngIf="loading"></app-progress-spinner>

	<div class="input__indicator"></div>
</div>

<ng-content select="[input-error]"></ng-content>
