import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TableModule as PNGTable} from 'primeng/table';
import {DropdownModule as PNGDropdown} from 'primeng/dropdown';
import {CalendarModule as PNGCalendar} from 'primeng/calendar';
import {SortablejsModule} from 'ngx-sortablejs';

import {PipesModule} from 'src/app/shared/pipes/pipes.module';
import {ButtonModule} from 'src/app/shared/components/button/button.module';
import {ButtonGroupModule} from 'src/app/shared/components/button-group/button-group.module';
import {DrawerModule} from 'src/app/shared/components/drawer/drawer.module';
import {DropdownModule} from 'src/app/shared/components/dropdown/dropdown.module';
// import {FromModule} from 'src/app/shared/components/form/form.module';
import {InputModule} from 'src/app/shared/components/input/input.module';
import {InputRadioModule} from 'src/app/shared/components/input-radio/input-radio.module';
import {IconModule} from 'src/app/shared/components/icon/icon.module';
// import {ModalModule} from 'src/app/shared/components/modal/modal.module';
import {ProgressSpinnerModule} from 'src/app/shared/components/progress-spinner/progress-spinner.module';
import {SortableListModule} from 'src/app/shared/components/sortable-list/sortable-list.module';
import {StatusIndicatorModule} from 'src/app/shared/components/status-indicator/status-indicator.module';

// Components
import {TableComponent} from './table.component';
import {TableFilterComponent} from './components/table-filter/table-filter.component';
import {TableFilterFormComponent} from './components/table-filter-form/table-filter-form.component';
import {TableLayoutFormComponent} from './components/table-layout-form/table-layout-form.component';
import {TableLayoutPickerComponent} from './components/table-layout-picker/table-layout-picker.component';
import {TableNavBtnDirective} from './directives/table-nav-btn.directive';
import {TableNavContentDirective} from './directives/table-nav-content.directive';
import {TableNavItemDirective} from './directives/table-nav-item.directive';
import {TableSearchComponent} from './components/table-search/table-search.component';
import {TableSortComponent} from './components/table-sort/table-sort.component';
import {TableSummaryContentDirective} from './directives/table-summary-content.directive';

const components = [
	TableComponent,
	TableFilterComponent,
	TableFilterFormComponent,
	TableLayoutFormComponent,
	TableLayoutPickerComponent,
	TableNavBtnDirective,
	TableNavContentDirective,
	TableNavItemDirective,
	TableSearchComponent,
	TableSortComponent,
	TableSummaryContentDirective,
];

@NgModule({
	imports: [
		CommonModule,
		PipesModule,
		FormsModule,
		PNGTable,
		PNGDropdown,
		PNGCalendar,
		SortablejsModule,
		ButtonModule,
		ButtonGroupModule,
		DrawerModule,
		DropdownModule,
		InputModule,
		InputRadioModule,
		IconModule,
		ProgressSpinnerModule,
		SortableListModule,
		StatusIndicatorModule,
	],
	declarations: [...components],
	exports: [...components],
})
export class TableModule {}
