import {Component, OnInit, Input, ViewEncapsulation, ElementRef, HostBinding, HostListener} from '@angular/core';
import {UtilService} from 'src/app/core';

type ButtonTheme = 'primary' | 'success' | 'info' | 'warning' | 'danger' | 'neutral' | 'white' | 'none';
type ButtonShape = 'square' | 'rounded' | 'round';
type ButtonFill = 'solid' | 'outline';
type ButtonSize = 'small' | 'medium' | 'large';

@Component({
	selector: '[btn], [btn-icon]',
	templateUrl: './button.component.html',
	styleUrls: ['./button.component.scss'],
	host: {
		'class': 'btn',
		'[attr.tabindex]': 'disabled ? -1 : (tabIndex || 0)',
		'[attr.disabled]': 'disabled || null',
		'[attr.aria-disabled]': 'disabled.toString()',
	},
	encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit {
	private el!: HTMLElement;
	private isIcon!: boolean;

	@Input() active = false;
	@Input() loading = false;
	@Input() ripple = true;
	@Input() disabled = false;
	@Input() theme: ButtonTheme = 'primary';
	@Input() shape: ButtonShape = 'rounded';
	@Input() fill: ButtonFill = 'solid';
	@Input() size: ButtonSize = 'large';
	@Input() expand = false;

	@HostBinding('class')
	get classes(): string {
		const classes: string[] = [];

		classes.push(`-${this.theme}`);
		classes.push(`-${this.shape}`);
		classes.push(`-${this.fill}`);
		classes.push(`-${this.size}`);

		if (this.expand) {
			classes.push(`-block`);
		}

		if (this.isIcon) {
			classes.push('-icon');
		}

		if (this.loading) {
			classes.push('-loading');
		}

		if (this.active) {
			classes.push('-active');
		}

		return classes.join(' ');
	}

	constructor(private elRef: ElementRef, private util: UtilService) {}

	public ngOnInit(): void {
		this.el = this.elRef.nativeElement;
		this.isIcon = this.el.hasAttribute('btn-icon');

		if (this.isIcon && !this.el.hasAttribute('title') && !this.el.hasAttribute('aria-label')) {
			this.util.error('Button does not have an accessible name.', this.el);
		}
	}

	@HostListener('click', ['$event'])
	public createRipple(e: MouseEvent): void {
		if (!this.ripple) return;

		const target = e.target as HTMLElement;
		const targetRect = target.getBoundingClientRect();
		const ripple = document.createElement('span');

		// Get position
		let x = e.clientX - targetRect.left;
		let y = e.clientY - targetRect.top;

		if (this.isIcon) {
			x = targetRect.width / 2;
			y = targetRect.height / 2;
		}

		// Append ripple
		ripple.classList.add('btn__ripple');
		ripple.style.left = `${x}px`;
		ripple.style.top = `${y}px`;
		this.el.appendChild(ripple);

		// Remove Ripple
		setTimeout(() => {
			ripple.remove();
		}, 2000);
	}
}
