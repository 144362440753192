import {Component, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import {get} from 'lodash-es';
import {NotificationsService, StorageService, UtilService} from 'src/app/core';
import {TableColumn, TableData, TableLayout, TableLayoutColumn} from 'src/app/models';
import {FormComponent} from 'src/app/shared/components/form/form.component';
import {DropdownComponent} from '../../../dropdown/dropdown.component';
import {TableService} from '../../services/table.service';
import {TableFilterFormComponent} from '../table-filter-form/table-filter-form.component';

@Component({
	selector: 'app-table-layout-form',
	host: {
		class: 'table-layout-form',
	},
	templateUrl: './table-layout-form.component.html',
	styleUrls: ['./table-layout-form.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TableLayoutFormComponent extends FormComponent {
	get uid() {
		return this.tableService.uid;
	}

	get table() {
		return this.tableService.table;
	}

	get tableData() {
		return this.tableService.tableData;
	}
	set tableData(value: TableData) {
		this.tableService.tableData = value;
	}

	get columns() {
		return this.tableService.columns;
	}
	set columns(columns: TableColumn[]) {
		this.tableService.columns = columns;
	}

	get selectedLayout() {
		return this.tableService.selectedLayout;
	}
	set selectedLayout(value: TableLayout | undefined | null) {
		this.tableService.selectedLayout = value;
	}

	constructor(
		public notifications: NotificationsService,
		public storage: StorageService,
		public util: UtilService,
		public tableService: TableService,
	) {
		super(notifications, util);
	}

	// Lifecycle
	// ----------------------------------------

	public async handleSubmit(form: NgForm) {
		if (!this.selectedLayout) return;

		if (!this.selectedLayout.id) {
			this.selectedLayout.id = Date.now().toString();
		}

		const data = this.tableData;
		const index = data.layouts.findIndex((l) => l.id === this.selectedLayout!.id);

		if (index >= 0) {
			data.layouts.splice(index, 1, this.selectedLayout);
		} else {
			data.layouts.push(this.selectedLayout);
		}

		if (this.selectedLayout.default) {
			data.layouts.forEach((l) => {
				if (l.id === this.selectedLayout!.id) return;
				l.default = false;
			});
		}

		this.tableData = data;
		return data;
	}

	// Util
	// ----------------------------------------

	public updateSort(col: TableLayoutColumn) {
		if (!this.selectedLayout) return;

		if (this.selectedLayout.sortField !== col.field) {
			this.selectedLayout.sortField = col.field;
			this.selectedLayout.sortOrder = 1;
			return;
		}

		if (this.selectedLayout.sortOrder !== -1) {
			this.selectedLayout.sortOrder = -1;
			return;
		}

		delete this.selectedLayout.sortField;
		delete this.selectedLayout.sortOrder;
	}

	public getLayoutById(id: string) {
		return this.tableData?.layouts.find((l) => l.id === id);
	}

	public getColumnByField(field: string) {
		return this.columns.find((c) => c.field === field);
	}

	public getColumnValue(col: TableLayoutColumn, path: string) {
		const tableColumn = this.getColumnByField(col.field);

		if (tableColumn) {
			return get(tableColumn, path);
		} else {
			return;
		}
	}

	public isLayoutActive(layout: TableLayout) {
		return layout.id === this.selectedLayout?.id;
	}

	public isSortActive(col: TableLayoutColumn) {
		return this.selectedLayout?.sortField === col.field;
	}

	public clearFilters(filterMenu: DropdownComponent, filterForm: TableFilterFormComponent) {
		filterForm.clearFilters();
		filterMenu.close();
	}
}
