import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {SessionService, UtilService} from 'src/app/core';

@Injectable({
	providedIn: 'root',
})
export class LoggedOutGuard implements CanActivate {
	constructor(private router: Router, private session: SessionService, private util: UtilService) {}

	public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.session.isLoggedIn()) return true;

		this.util.info('User is logged in.');
		this.router.navigate(['animals']);
		return false;
	}
}
