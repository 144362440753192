import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DropdownComponent} from './dropdown.component';
import {DropdownButtonsDirective} from './directives/dropdown-buttons.directive';
import {DropdownContentDirective} from './directives/dropdown-content.directive';
import {DropdownItemDirective} from './directives/dropdown-item.directive';
import {DropdownToggleDirective} from './directives/dropdown-toggle.directive';

const components = [
	DropdownComponent,
	DropdownButtonsDirective,
	DropdownContentDirective,
	DropdownItemDirective,
	DropdownToggleDirective,
];

@NgModule({
	imports: [CommonModule],
	declarations: [...components],
	exports: [...components],
})
export class DropdownModule {}
