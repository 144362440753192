import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SessionService} from 'src/app/core';

@Injectable({
	providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
	constructor(private session: SessionService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Skip non-api requests
		if (request.url.indexOf('cloudapi.tepari.com') === -1) {
			return next.handle(request);
		}

		request = this.addToken(request);

		return next.handle(request);
	}

	private addToken(request: HttpRequest<any>) {
		const token = this.session.token;

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	}
}
