<div class="wrapper">
	<header class="header">
		<a
			[routerLink]="['/animals/mobs']"
			[routerLinkActive]="'-active'"
			class="logo"
			i18n-aria-label
			aria-label="Home"
		>
			<app-icon name="logo" class="logo__img"></app-icon>
			<p class="logo__name">T30</p>
		</a>

		<button
			type="button"
			class="menu-toggle"
			(click)="toggleMenu()"
			[attr.aria-expanded]="isMenuOpen"
			[class.-active]="isMenuOpen"
			i18n-aria-label
			aria-label="Menu Toggle"
			aria-controls="aria-menu"
		>
			<div class="menu-toggle__inner"></div>
		</button>

		<ul class="nav -horizontal ml-auto">
			<li class="nav__item">
				<a
					[routerLink]="['/search']"
					[routerLinkActive]="'-active'"
					class="link -white"
					i18n-title
					i18n-aria-label
					title="Search"
					aria-label="Search"
				>
					<app-icon name="search" class="link__icon"></app-icon>
				</a>
			</li>

			<li class="nav__item">
				<a
					[routerLink]="['/profile']"
					[routerLinkActive]="'-active'"
					class="link -white"
					i18n-title
					i18n-aria-label
					title="My Profile"
					aria-label="My Profile"
				>
					<app-icon name="user" class="link__icon"></app-icon>
				</a>
			</li>
		</ul>
	</header>

	<div id="aria-menu" class="menu" [class.-active]="isMenuOpen">
		<div class="menu__inner">
			<nav class="menu__nav">
				<ul class="nav">
					<li class="nav__item" *ngFor="let navItem of navItems">
						<ng-container
							[ngTemplateOutlet]="navItem.dropdownItems ? itemWithDropdownTemp : itemTemp"
							[ngTemplateOutletContext]="{navItem: navItem}"
						></ng-container>
					</li>

					<!-- <li id="nav-item-import" class="nav__item">
						<button type="button" class="link -neutral" (click)="modalImport.open()">
							<app-icon name="doc-add" class="link__icon"></app-icon>
							<span class="link__label" i18n>Import From File</span>
						</button>
					</li> -->
				</ul>
			</nav>

			<footer class="menu__footer">
				<ul class="nav">
					<li class="nav__item">
						<button type="button" class="link -neutral -small" (click)="session.logout()">
							<app-icon name="logout" class="link__icon"></app-icon>
							<span class="link__label" i18n>Logout</span>
						</button>
					</li>
				</ul>
			</footer>
		</div>
	</div>
</div>

<ng-template #itemWithDropdownTemp let-navItem="navItem">
	<a
		class="link"
		[routerLink]="navItem.path"
		[routerLinkActive]="'-active'"
		[routerLinkDisabled]="!dropdownsEnabled()"
		(click)="navItem.dropdownOpen = !navItem.dropdownOpen"
	>
		<app-icon *ngIf="navItem.icon" [name]="navItem.icon" class="link__icon"></app-icon>
		<span class="link__label" i18n>{{ navItem.label }}</span>
	</a>

	<button
		class="dropdown-nav-toggle"
		[class.-active]="navItem.dropdownOpen"
		(click)="navItem.dropdownOpen = !navItem.dropdownOpen"
	>
		<app-icon name="arrow-small"></app-icon>
	</button>

	<ul class="dropdown-nav" *ngIf="navItem.dropdownOpen" [@dropdownAnimation]>
		<li class="dropdown-nav__item" *ngFor="let dropdownItem of navItem.dropdownItems">
			<ng-container
				[ngTemplateOutlet]="itemTemp"
				[ngTemplateOutletContext]="{navItem: dropdownItem}"
			></ng-container>
		</li>
	</ul>
</ng-template>

<ng-template #itemTemp let-navItem="navItem">
	<a class="link" [routerLink]="navItem.path" [routerLinkActive]="'-active'">
		<app-icon *ngIf="navItem.icon" [name]="navItem.icon" class="link__icon"></app-icon>
		<span class="link__label" i18n>{{ navItem.label }}</span>
	</a>
</ng-template>
