import {Directive, ElementRef} from '@angular/core';

@Directive({
	selector: '[card-title]',
	host: {class: 'card__title'},
})
export class CardTitleDirective {
	public el: HTMLElement;

	constructor(public elRef: ElementRef) {
		this.el = this.elRef.nativeElement;
	}
}
