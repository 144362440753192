<label>
	<span *ngIf="label" class="date-picker__label" (click)="inputDate.toggle()">{{ label }}:</span>

	<div class="date-picker__inner">
		<input
			#inputDate
			input-date
			[minDate]="minDate"
			[maxDate]="maxDate"
			[enableDates]="enableDates"
			[disableDates]="disableDates"
			[(ngModel)]="date"
			(ngModelChange)="onChange()"
		/>

		<button class="date-picker__btn" (click)="inputDate.toggle()">
			<span>{{ date | date }}</span>
			<app-icon name="calendar"></app-icon>
		</button>
	</div>
</label>
