<button
	type="button"
	class="table-sort-btn"
	[ngClass]="{
		'-asc': table.sortOrder === 1,
		'-desc': table.sortOrder === -1,
		'-active': table.sortField === column.field
	}"
	btn-icon
	theme="primary"
	shape="round"
	aria-label="Sort Column"
	[pSortableColumn]="column.field"
>
	<app-icon name="sort"></app-icon>
</button>
