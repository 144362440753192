<button
	type="button"
	class="table-btn-filter"
	btn-icon
	theme="primary"
	shape="round"
	i18n-title
	title="Filter Column"
	[class.-active]="filterForm.isActive"
	[dropdown]="filterMenu"
>
	<app-icon name="filter"></app-icon>
</button>

<app-dropdown #filterMenu [relocate]="false">
	<app-dropdown-content>
		<app-table-filter-form
			#filterForm
			[(filters)]="filters"
			[type]="type"
			[field]="field"
			[options$]="options$"
			[optionsValue]="optionsValue"
			[optionsLabel]="optionsLabel"
		></app-table-filter-form>
	</app-dropdown-content>

	<app-dropdown-buttons>
		<button type="button" btn theme="primary" fill="solid" shape="square" size="medium" (click)="applyFilters()">
			<ng-container i18n>apply</ng-container>
		</button>
		<button type="button" btn theme="primary" fill="solid" shape="square" size="medium" (click)="clearFilters()">
			<ng-container i18n>Clear</ng-container>
		</button>
	</app-dropdown-buttons>
</app-dropdown>
