import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmbedModule} from 'src/app/shared/components/embed/embed.module';

import {ChartComponent} from './chart.component';

@NgModule({
	imports: [CommonModule, EmbedModule],
	declarations: [ChartComponent],
	exports: [ChartComponent],
})
export class ChartModule {}
