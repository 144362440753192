import {Component, Input} from '@angular/core';

@Component({
	selector: 'app-status-indicator',
	templateUrl: './status-indicator.component.html',
	styleUrls: ['./status-indicator.component.scss'],
})
export class StatusIndicatorComponent {
	@Input() status: 'Alive' | 'Dead' | 'Culled' | 'Sold' | 'Reference' | 'Unknown' | undefined;

	constructor() {}
}
