import {style, animate, animation} from '@angular/animations';

// prettier-ignore
export const zoomIn = animation([
	style({opacity: 0, transform: 'scale({{ scale }})'}), 
	animate(
		'{{ timings }}', 
		style({opacity: 1, transform: 'scale(1)'})
	)
]);

// prettier-ignore
export const zoomOut= animation([
	style({opacity: 1, transform: 'scale(1)'}), 
	animate(
		'{{ timings }}', 
		style({opacity: 0, transform: 'scale({{ scale }})'})
	)
]);
