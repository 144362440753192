import {OnChanges, OnDestroy, Optional, SimpleChanges} from '@angular/core';
import {AfterContentInit, Component, ElementRef, Input, Renderer2, ViewEncapsulation} from '@angular/core';
import {NgModel} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
	selector: '[input-date]',
	host: {
		class: 'input-date',
	},
	template: '<ng-content></ng-content>',
	styleUrls: ['./input-date.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class InputDateComponent implements AfterContentInit, OnChanges, OnDestroy {
	@Input() altFormat = 'J F Y';
	@Input() minDate!: string;
	@Input() maxDate!: string;
	@Input() enableDates!: string[];
	@Input() disableDates!: string[];
	@Input() disabled: string | boolean | null = null;

	public el!: HTMLElement;
	public elAlt!: HTMLElement;
	public instance: any;
	public flatpickr!: any;

	private unsubscribe$: Subject<boolean> = new Subject();

	constructor(public elRef: ElementRef, private renderer: Renderer2, @Optional() private ngModel: NgModel) {}

	public async ngAfterContentInit(): Promise<void> {
		this.el = this.elRef.nativeElement;

		// Init Flatpickr
		const options: any = {
			static: true,
			altInput: true,
			altFormat: this.altFormat,
			altInputClass: 'date-picker',
			disableMobile: 'true',
		};

		if (this.minDate) options.minDate = this.minDate;

		if (this.maxDate) options.maxDate = this.maxDate;

		if (this.enableDates) options.enable = this.enableDates;

		if (this.disableDates) options.enable = this.disableDates;

		if (!this.flatpickr) {
			const Flatpickr = await import('flatpickr');
			this.flatpickr = Flatpickr.default;
		}

		this.instance = this.flatpickr(this.el, options);

		// Get the alt input el
		this.elAlt = this.instance._input;

		// Set disabled on both inputs
		if (this.disabled) {
			this.disable();
		}

		// Update value on model change
		this.ngModel?.valueChanges?.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
			this.instance.setDate(value);
		});
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.minDate && changes.minDate.currentValue) {
			this.setOption('minDate', changes.minDate.currentValue);
		}

		if (changes.maxDate && changes.maxDate.currentValue) {
			this.setOption('minDate', changes.maxDate.currentValue);
		}

		if (changes.disabled) {
			if (changes.disabled.currentValue) {
				this.disable();
			} else {
				this.enable();
			}
		}
	}

	public ngOnDestroy(): void {
		if (this.instance) {
			this.instance.destroy();
		}

		this.unsubscribe$.next(true);
		this.unsubscribe$.complete();
	}

	public setOption(option: string, value: any): void {
		if (this.instance) {
			this.instance.set(option, value);
		}
	}

	public disable(): void {
		if (!this.el || !this.elAlt) return;

		this.renderer.setAttribute(this.el, 'disabled', 'disabled');
		this.renderer.setAttribute(this.elAlt, 'disabled', 'disabled');
	}

	public enable(): void {
		if (!this.el || !this.elAlt) return;

		this.renderer.removeAttribute(this.el, 'disabled');
		this.renderer.removeAttribute(this.elAlt, 'disabled');
	}

	public toggle() {
		if (!this.instance) return;

		if (this.instance.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	public open(): void {
		if (!this.instance) return;

		setTimeout(() => {
			this.instance.open();
		}, 0);
	}

	public close(): void {
		if (!this.instance) return;

		setTimeout(() => {
			this.instance.close();
		}, 0);
	}
}
